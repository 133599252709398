import {
  IonList,
  IonText,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonLoading,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { decodeParam } from "../util/ApiHelper";
import { getWorkOrder } from "../api/WorkOrders";
import { TranslationMessagesContext } from "../util/Translations";

const PermitsTab: React.FC = () => {
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const [permits, setPermits] = useState<any[]>([]);
  const [permitsErrorState, setPermitsErrorState] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setPermits([]);
    setPermitsErrorState(false);
    // Show loading symbol
    setShowLoading(true);

    getWorkOrder({
      woNumber: displayWo,
      permits: true,
    }).then((response) => {
      if (response.status !== 200) {
        setPermits([]);
        setPermitsErrorState(true);
      } else if (response.data) {
        if (response.data.Permits && response.data.Permits.length > 0) {
          setPermits(response.data.Permits);
          setPermitsErrorState(false);
        } else {
          setPermits([]);
          setPermitsErrorState(false);
        }
      }
      // Hide loading symbol once all the calls are done
      setShowLoading(false);
    });
  }, [workOrderId]);

  return (
    <>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      {!!permits && permits.length > 0 ? (
        <IonList>
          {permits.map((item, index) => {
            return (
              <IonItem key={index} lines="none">
                <IonCheckbox slot="start" checked disabled></IonCheckbox>
                <IonLabel style={{ opacity: 1 }}>{item.Permit}</IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      ) : (
        <IonText
          color={permitsErrorState ? "danger" : "default"}
          style={{ padding: "16px", display: "inline-block" }}
        >
          {permitsErrorState
            ? `${
                translatedMessages["ErrPermits"]?.MessageText ||
                "Error retrieving permits"
              }`
            : `${
                translatedMessages["NoResultsFound"]?.MessageText ||
                "No results found."
              }`}
        </IonText>
      )}
    </>
  );
};

export default PermitsTab;
