import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route, Switch } from "react-router-dom";
import { useEffect, useState } from "react";
import AppTabs from "./AppTabs";
import NotFoundPage from "./pages/NotFoundPage";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import {
  setTheme,
  themeDetectionHandler,
  ThemeContext,
} from "./util/ThemeManager";
import { hideSplashScreen } from "./util/SplashScreen";
import AuthCallback from "./pages/AuthCallback";
import { getThemePreference } from "./util/Preferences";
import { ThemePreference } from "./models/common/ThemePreference";
import { AuthContext } from "./util/OAuth";
import PortalAuthCallback from "./pages/PortalAuthCallback";
import { getScreenOrientation } from "./util/Device";
import {
  TranslationsContext,
  TranslationMessagesContext,
} from "./util/Translations";
import { ErrorInfo } from "./util/ErrorHelper";
import AppUrlListener from "./pages/AppUrlListener";
import QRCodeService from "./pages/QRCodeService";

setupIonicReact();

const App: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [themePreference, setThemePreference] = useState<ThemePreference>();
  const [translations, setTranslations] = useState<any>({});
  const [translatedMessages, setTranslatedMessages] = useState<any>({});
  const [error, setError] = useState<ErrorInfo>();

  useEffect(() => {
    getScreenOrientation().then(() => {
      hideSplashScreen({ fadeOutDuration: 1000 });
    });
  }, []);

  useEffect(() => {
    getThemePreference().then((storedThemePreference) => {
      setThemePreference(storedThemePreference as ThemePreference);
    });
    // Use matchMedia to check the user preference on app start
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
    // Add an event listener to change the theme on system preference change if the app setting is set to system default
    // IMPORTANT: This IF statement is added to address Safari on iOS 13.*.* bug where the prefers-color-scheme media query does not work
    if (prefersDark?.addEventListener) {
      prefersDark.addEventListener("change", (event) => {
        themeDetectionHandler(event, themePreference);
      });
    } else {
      // deprecated method but used in to support IOS 13, which does not have the addEventListener method
      prefersDark.addListener((event) => {
        themeDetectionHandler(event, themePreference);
      });
    }
  }, []);

  useEffect(() => {
    themePreference && setTheme(themePreference);
  }, [themePreference]);

  return (
    <IonApp class="scanner-hide">
      <AuthContext.Provider value={{ loggedIn, setLoggedIn }}>
        <ThemeContext.Provider value={{ themePreference, setThemePreference }}>
          <TranslationsContext.Provider
            value={{ translations, setTranslations }}
          >
            <TranslationMessagesContext.Provider
              value={{ translatedMessages, setTranslatedMessages }}
            >
              <IonReactRouter>
                
              <Switch>
                  <Route path="/" exact={true}>
                    <AuthCallback />
                  </Route>
                  <Route path="/AuthCallback">
                    <AuthCallback />
                  </Route>
                  <Route path="/PortalAuthCallback">
                    <PortalAuthCallback />
                  </Route>
                  <Route path="/qrcodesvc">
                    <QRCodeService />
                  </Route>
                  <AppTabs />
                  <Route>
                    <NotFoundPage />
                  </Route>
                </Switch>
                <AppUrlListener></AppUrlListener>
              </IonReactRouter>
            </TranslationMessagesContext.Provider>
          </TranslationsContext.Provider>
        </ThemeContext.Provider>
      </AuthContext.Provider>
    </IonApp>
  );
};

export default App;
