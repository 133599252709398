import React, { useEffect, useState } from "react";
import { ColumnsWithGroupData, getColumnsWithGroup } from "../api/Windows";
import { GetColumnsWithGroupResponse } from "../models/windows/GetColumnsWithGroup";
import MaterialIssueDetails from "../components/MaterialIssueDetails";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import MaterialIssueItemList from "../components/MaterialIssueItemList";
import { add, chevronBackOutline } from "ionicons/icons";
import OnlineStatus from "../components/OnlineStatus";
import {
  MaterialIssueListItem,
  PlannedListItem,
} from "../models/materialissue/MaterialIssue";
import {
  ReturnMaterialsBody,
  getPlannedList,
  returnMaterials,
} from "../api/Materials";
import { ItemListEntry } from "./MaterialIssue";
import { useIssueReturnManager } from "../hooks/useIssueReturnManager";
import MaterialIssueItemSearch from "../components/MaterialItemSearch";

const IssueReturn: React.FC = () => {
  const {
    employees,
    employeeId,
    storeLocation,
    itemList,
    dateCharged,
    searchOpen,
    displayWo,
    displayReq,
    accountObject,
    translations,
    showLoading,
    setShowLoading,
    setEmployeeId,
    setStoreLocation,
    setItemList,
    setDateCharged,
    setSearchOpen,
    setupDetails,
    getBinData,
    onItemSelect,
    onSelectAll,
    onBinAmountChange,
    onResponse,
    onComment,
    onItemAdd,
  } = useIssueReturnManager();
  const [issueDetailsColumns, setIssueDetailsColumns] =
    useState<GetColumnsWithGroupResponse>();
  const [presentToast] = useIonToast();

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    if (storeLocation && (displayWo || displayReq)) {
      getPlannedListItems(storeLocation, displayWo, displayReq);
    }
  }, [storeLocation, displayWo, displayReq]);

  const getReturnDetailsColumns = async () => {
    const data: ColumnsWithGroupData = {
      WindowName: "mob_inventory_return_details",
      IncludeValidValues: "true",
    };
    const response = await getColumnsWithGroup(data);
    return response.data as GetColumnsWithGroupResponse;
  };

  const setup = async () => {
    setShowLoading(true);
    const issueDetailsColumns = await getReturnDetailsColumns();
    setIssueDetailsColumns(issueDetailsColumns);
    setupDetails();
    setShowLoading(false);
  };

  const getPlannedListItems = async (
    storeLocation: string,
    workOrder: string,
    requisition: string
  ) => {
    const body = {
      requisition: requisition,
      storesLocation: storeLocation,
      workOrder: workOrder,
    };
    setShowLoading(true);
    const response = await getPlannedList(body);
    const items = await attachBinData(response.data?.MaterialList || [], false);
    setItemList(items);
    setShowLoading(false);
  };

  const attachBinData = async (data: PlannedListItem[], isAdhoc: boolean) => {
    const items: ItemListEntry[] = [];
    for (const item of data) {
      const binData = await getBinData(item.Item, item.StoresLocation);
      items.push({
        item: item,
        binData: binData,
        checked: false,
        issued: false,
        isAdhoc,
        comments: "",
      });
    }
    return items;
  };

  const isReadyToIssueReturn = () => {
    const checkedList = itemList.filter((entry) => {
      return entry.checked;
    });
    if (checkedList.length === 0) {
      return false;
    }
    let amount = 0;
    for (const entry of checkedList) {
      for (const bin of entry.binData) {
        amount += bin.TransactionQuantity || 0;
      }
    }
    return amount > 0;
  };

  const issueReturn = async () => {
    if (!storeLocation || !employeeId) {
      return;
    }
    const materialIssueList: MaterialIssueListItem[] = [];
    const selectedItems = itemList.filter((entry) => {
      return entry.checked;
    });
    selectedItems.forEach((entry, index) => {
      const bins = [];
      for (const bin of entry.binData) {
        if (bin.TransactionQuantity && bin.TransactionQuantity > 0) {
          bins.push({
            Bin: bin.Bin,
            MaterialIssueId: bin.ObjectId,
            TransactionQuantity: bin.TransactionQuantity,
          });
        }
      }
      if (bins.length > 0) {
        materialIssueList.push({
          Comments: entry.comments,
          Item: entry.item.Item,
          ItemIndex: index,
          Requisition: entry.item.Requisition,
          RequisitionLine: entry.item.RequisitionLine,
          SerialIds: [],
          StoresLocation: storeLocation,
          Bins: bins,
        });
      }
    });

    const body: ReturnMaterialsBody = {
      WorkOrder: displayWo,
      ReturnDate: dateCharged,
      ReturnFromUser: employeeId.EmployeeId,
      Account: {
        Account:
          !!accountObject && !!accountObject.Account
            ? accountObject.Account
            : "",
        Area: !!accountObject && !!accountObject.Area ? accountObject.Area : "",
        Department:
          !!accountObject && !!accountObject.Department
            ? accountObject.Department
            : "",
        Project:
          !!accountObject && !!accountObject.Project
            ? accountObject.Project
            : "",
      },
      MaterialReturnList: materialIssueList,
    };

    setShowLoading(true);
    const response = await returnMaterials(body);
    setShowLoading(false);
    if (response.isError) {
      presentToast({
        message: String(response.data),
        duration: 5000,
        color: "error",
      });
    } else {
      onResponse(response.data);
    }
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/home" icon={chevronBackOutline} />
            </IonButtons>
            <IonTitle>
              {displayWo || displayReq || accountObject.Account}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                disabled={!isReadyToIssueReturn()}
                onClick={issueReturn}
              >
                {translations["lbl_btn_return"] || "Return"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <OnlineStatus />
        </IonHeader>

        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          duration={5000}
        />

        <IonContent class="ion-padding">
          {issueDetailsColumns && (
            <MaterialIssueDetails
              columns={issueDetailsColumns}
              storeLocation={storeLocation}
              setStoreLocation={setStoreLocation}
              employeeId={employeeId}
              setEmployeeId={setEmployeeId}
              employees={employees}
              date={dateCharged}
              setDate={setDateCharged}
              mode="return"
              displayWo={displayWo}
              displayReq={displayReq}
            />
          )}

          <div className="ion-margin-bottom"></div>

          <MaterialIssueItemList
            itemList={itemList}
            onSelect={onItemSelect}
            onSelectAll={onSelectAll}
            onBinAmountChange={onBinAmountChange}
            mode="return"
            handleComment={onComment}
          />

          <IonFab slot="fixed" vertical="bottom" horizontal="end">
            <IonFabButton
              onClick={() => {
                setSearchOpen(true);
              }}
              disabled={!storeLocation}
            >
              <IonIcon icon={add}></IonIcon>
            </IonFabButton>
          </IonFab>
        </IonContent>
      </IonPage>
      {searchOpen && (
        <MaterialIssueItemSearch
          isOpen={searchOpen}
          onSelect={onItemAdd}
          onCancel={() => {
            setSearchOpen(false);
          }}
          storeLocation={storeLocation}
          issueOrReturn="return"
        />
      )}
    </>
  );
};

export default IssueReturn;
