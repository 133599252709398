import {
  InputChangeEventDetail,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import OnlineStatus from "./OnlineStatus";
import { useContext, useEffect, useState } from "react";
import { TranslationsContext } from "../util/Translations";
import { getDataForCycleItem } from "../api/CycleCountGroups";
import {
  CountEntryCard,
  InventoryField,
  MaterialIssueModal,
  formatData,
  getAllFields,
} from "../models/materialissue/MaterialIssue";
import {
  barcodeOutline,
  barcodeSharp,
  checkbox,
  chevronBackOutline,
  chevronDownOutline,
  chevronUpOutline,
  filterCircleOutline,
  filterCircleSharp,
  squareOutline,
} from "ionicons/icons";
import {
  CountEntryRequestData,
  countEntryOfItems,
} from "../api/CycleCountBatchs";
import { getOptionByIdWithCORP } from "../api/TabwareOptions";
import { IonInputCustomEvent } from "@ionic/core";
import { ColumnsWithGroupData, getColumnsWithGroup } from "../api/Windows";
import { GetColumnsWithGroupResponse } from "../models/windows/GetColumnsWithGroup";
import { useStoresManager } from "../hooks/useStoresManager";
import { useHistory, useParams } from "react-router";
import { decodeParam } from "../util/ApiHelper";
import {
  performBarCodeShimProcess,
  findObjectByElementType,
} from "../util/BarCodeHelper";
import { useBarcodeManager } from "../hooks/useBarcodeManager";

const CycleCountEntry: React.FC<MaterialIssueModal> = () => {
  const history = useHistory();
  const [metadata, setMetadata] = useState<GetColumnsWithGroupResponse>();
  const [itemField, setItemField] = useState<GetColumnsWithGroupResponse>();
  const { batchId } = useParams<{ batchId: string }>();
  const displayBatchId = decodeParam(batchId);
  const { isCommentsRequired, checkRequireComments } = useStoresManager();
  const [presentAlert] = useIonAlert();
  const { translations } = useContext(TranslationsContext);
  const [showLoading, setShowLoading] = useState(false);
  const [countEntryDetails, setCountEntryDetails] = useState<CountEntryCard[]>(
    []
  );
  const [countData, setCountData] = useState<CountEntryCard[]>([]);
  const [countEntryDisplay, setCountEntryDisplay] = useState<CountEntryCard>();
  const [scanOption, setScanOption] = useState<string>("Item");
  const { scanText, setScanText, scanClick } = useBarcodeManager();
  const [includeCounted, setIncludeCounted] = useState<boolean>(false);
  const [count, setCount] = useState<number>();
  const [expanded, setExpanded] = useState(false);
  const [comments, setComments] = useState<string>("");
  const [resultsFilter, setResultsFilter] = useState("");
  const [multipleSelect, setMultipleSelect] = useState<number[]>([]);
  const EAMDefaultDate = "0001-01-01T00:00:00";
  const [itemVal, setItemVal] = useState<string>("");

  //TODO: create component for scanner with text and button together
  const getCountEntry = async () => {
    setShowLoading(true);
    setMultipleSelect([]);
    setCountEntryDetails([]);
    setCountData([]);
    setScanText("");
    const data = {
      batchId: Number(displayBatchId),
    };
    getDataForCycleItem(data).then((response) => {
      setCountEntryDetails(response.data.data);
      setCountData(response.data.data);
      setCountEntryDisplay(response.data.data);
      setShowLoading(false);
    });

    //TODO: create a helper to get options and use in all places calling this api
    const response = await getOptionByIdWithCORP(
      "CycleCountingScannerConfiguration"
    );
    if (response.status === 200 && response?.data) {
      //otherwise leave "Item" as default
      setScanOption(
        response.data.PlantValue
          ? response.data.PlantValue
          : response.data.DefaultValue
      );
    }
  };

  const getIssueDetailsColumns = async () => {
    const data: ColumnsWithGroupData = {
      WindowName: "mob_inventory_cyclecount",
    };
    const response = await getColumnsWithGroup(data);
    setMetadata(response.data);
  };

  const getScanItemField = async () => {
    const data: ColumnsWithGroupData = {
      WindowName: "mob_inventory_cyclecount_scan",
    };
    const response = await getColumnsWithGroup(data);
    setItemField(response.data);
  };

  const setCountValue = async (countValue: number, index: number) => {
    countEntryDetails[index].CountResult = countValue;
    const i = countData.findIndex((d) => {
      return d.CountId === countEntryDetails[index].CountId;
    });
    if (i > -1) {
      countData[i].CountResult = countValue;
    }
    setCount(countValue);
  };

  const handleIncludeCounted = async (selected: boolean) => {
    setIncludeCounted(selected);
  };

  const handleExpanded = async (expanded: boolean, index: number) => {
    countEntryDetails[index].expanded = expanded;
    setExpanded(countEntryDetails[index].expanded);
  };

  const handleComment = async (comments: string, index: number) => {
    countEntryDetails[index].Comments = comments;
    setComments(countEntryDetails[index].Comments);
  };

  const onFilterChange = async (event: any) => {
    setResultsFilter(event.target.value);
  };

  const saveCountEntry = async () => {
    for (let countEntry of countData) {
      if (countEntry.CountResult) {
        const data: CountEntryRequestData = {
          ...countEntry,
          CountedQty: String(countEntry.CountResult),
          Comments: comments,
        };
        const dataParams = {
          RequestObject: [data],
          Confirmation: {},
        };
        countEntryOfItems(dataParams).then((response) => {
          if (response.data.Success === false) {
            presentAlert({
              header: response.data.MessageList[0]?.Text,
              buttons: [
                {
                  text: `${translations["lbl_btn_ok"] || "OK"}`,
                },
              ],
            });
          } else if (response.data.CountEntryResults[0].Success === false) {
            presentAlert({
              header: response.data.CountEntryResults[0]?.MessageList[0]?.Text,
              buttons: [
                {
                  text: `${translations["lbl_btn_ok"] || "OK"}`,
                },
              ],
            });
          } else {
            presentAlert({
              header: "Cycle Count Entry successfully saved",
              buttons: [
                {
                  text: `${translations["lbl_btn_ok"] || "OK"}`,
                  handler: () => {
                    countEntry.SystemQty = countEntry.CountResult; // update Qty On Hand
                    history.goBack();
                  },
                },
              ],
            });
          }
        });
      }
    }
  };

  async function onScanTextEnter(
    ev: IonInputCustomEvent<InputChangeEventDetail>
  ): Promise<void> {
    let res = String(ev.target.value);
    const scanMatchList = filterItem(res);
    setItemVal(res);
    if (itemVal === res) return;
    if (scanMatchList.length === 0) {
      const barCodeFieldData = findObjectByElementType(itemField?.fields);
      const barcodeResult = await performBarCodeShimProcess(
        barCodeFieldData,
        res
      );
      if (barcodeResult) {
        if (barcodeResult.NoMatch) {
          setItemVal(res);
          setScanText(res);
          setCountEntryDetails(countData);
          return;
        }
        let capturingGroups = barcodeResult.CapturingGroupValues;
        if (capturingGroups?.Item || barcodeResult.FirstCapturingGroup) {
          res = capturingGroups?.Item || barcodeResult.FirstCapturingGroup;
          setItemVal(res);
          setCountEntryDetails(filterItem(res));
        } else {
          presentAlert("Scanned item not found.");
          setCountEntryDetails(countData);
        }
      }
    } else {
      setCountEntryDetails(scanMatchList);
    }
    setScanText(res);
    setResultsFilter("");
  }

  function filterItem(input: string) {
    return input
      ? countData.filter((d) => {
          return scanOption === "Item"
            ? d.Item.toLocaleLowerCase() === input.toLocaleLowerCase()
            : d.Item.toLocaleLowerCase() + d.Bin.toLocaleLowerCase() ===
                input.toLocaleLowerCase();
        })
      : countData;
  }

  const checkIncludeCounted = (entry: CountEntryCard) => {
    if (
      (!includeCounted && entry.DatetimeCounted !== EAMDefaultDate) ||
      (!multipleSelect.map(Number).includes(entry.GroupNumber) &&
        multipleSelect.length !== 0) ||
      entry.Item.toLocaleLowerCase().indexOf(
        resultsFilter.toLocaleLowerCase()
      ) === -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const expandedSection = (data: any, field: InventoryField, index: number) => {
    if (field.ElementType === "textArea") {
      return handleCommentInput(field.TranslatedIdText, index);
    } else if (
      field.FieldOrder !== "01" &&
      field.FieldOrder !== "02" &&
      field.FieldOrder !== "03" &&
      field.FieldOrder !== "04"
    ) {
      return (
        <p key={field.IdText}>
          {field.TranslatedIdText}: {formatData(data, field)}
        </p>
      );
    } else {
      return <></>;
    }
  };

  const handleCommentInput = (textField: string | undefined, index: number) => {
    return (
      <p>
        {textField}
        {isCommentsRequired ? "*:" : ":"}
        <IonInput
          class="ion-no-padding"
          style={{
            border: "0.25px solid lightgray",
          }}
          onIonInput={(ev) => {
            if (!ev.target.value) return;
            handleComment(String(ev.target.value), index);
          }}
        ></IonInput>
      </p>
    );
  };

  useEffect(() => {
    checkRequireComments();
    getIssueDetailsColumns();
    getCountEntry();
    getScanItemField();
  }, [batchId]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" icon={chevronBackOutline} />
          </IonButtons>
          <IonTitle>
            {translations["lbl_count_entry"] || "Count Entry"}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              disabled={!count}
              onClick={() => {
                saveCountEntry();
              }}
            >
              {translations["lbl_btn_save"] || "Save"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <OnlineStatus />
      </IonHeader>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={3000}
      />
      <IonContent>
        <IonButton
          fill="clear"
          size="small"
          onClick={() => {
            handleIncludeCounted(!includeCounted);
          }}
        >
          <IonLabel>
            {translations["lbl_include_counted"] || "Include Counted"}
          </IonLabel>
          <IonIcon
            icon={includeCounted ? checkbox : squareOutline}
            size="small"
          ></IonIcon>
        </IonButton>

        <IonList>
          <IonItem>
            <IonLabel position="floating">
              {translations["lbl_mob_filterbygroup"] || "Filter By Group"}:
            </IonLabel>
            <IonSelect
              placeholder="Select groups"
              value={multipleSelect}
              multiple={true}
              onIonChange={(ev) => setMultipleSelect(ev.detail.value)}
            >
              {Array.from(new Set(countData.map((a) => a.GroupNumber)))?.map(
                (g) => (
                  <IonSelectOption key={g}>{g}</IonSelectOption>
                )
              )}
            </IonSelect>
          </IonItem>
        </IonList>

        <IonItem>
          <IonInput
            type="text"
            clearInput={true}
            debounce={1500}
            class="ion-text-begin"
            placeholder={
              itemField?.fields["in_item_location.item"]?.TranslatedIdText
            }
            onIonChange={(ev) => onScanTextEnter(ev)}
            value={scanText}
          ></IonInput>
          <IonIcon
            slot="end"
            ios={barcodeOutline}
            md={barcodeSharp}
            onClick={() => scanClick()}
          ></IonIcon>
        </IonItem>
        <IonItem>
          <IonInput
            type="text"
            clearInput={true}
            debounce={500}
            class="ion-text-begin"
            placeholder={translations["lbl_filter"] || "Filter"}
            onIonChange={(ev) => onFilterChange(ev)}
            value={resultsFilter}
          ></IonInput>
          <IonIcon
            slot="end"
            ios={filterCircleOutline}
            md={filterCircleSharp}
          ></IonIcon>
        </IonItem>
        {!!metadata &&
          !!countEntryDisplay &&
          countEntryDetails.map((entry, index) => {
            return (
              <IonCard key={index} hidden={checkIncludeCounted(entry)}>
                <IonCardContent>
                  <IonGrid class="ion-no-padding">
                    <IonRow>
                      <IonCol>
                        <IonCardTitle>{entry.Item}</IonCardTitle>
                      </IonCol>
                      <IonCol size="auto" class="ion-no-padding">
                        <IonButton
                          size="small"
                          fill="clear"
                          onClick={() => {
                            handleExpanded(!expanded, index);
                          }}
                        >
                          <IonIcon
                            size="small"
                            slot="icon-only"
                            icon={
                              entry?.expanded
                                ? chevronUpOutline
                                : chevronDownOutline
                            }
                          ></IonIcon>
                        </IonButton>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCardSubtitle>{entry.Description}</IonCardSubtitle>
                    </IonRow>
                    <IonRow class="ion-padding-top">
                      <IonCol>
                        {getAllFields(metadata).map((field) => {
                          const data =
                            entry[field.PropertyName as keyof CountEntryCard];
                          return (
                            <>
                              {field.FieldOrder === "03" ||
                              field.FieldOrder === "04" ? (
                                <p key={field.IdText}>
                                  {field.TranslatedIdText}:{" "}
                                  {field.PropertyName === "QtyOnHand"
                                    ? entry.SystemQty
                                    : formatData(data, field)}
                                </p>
                              ) : (
                                <></>
                              )}
                              {entry.expanded &&
                                expandedSection(data, field, index)}
                            </>
                          );
                        })}
                      </IonCol>
                      <IonCol size="auto">
                        <IonItem
                          fill="outline"
                          mode="md"
                          style={{ maxWidth: 84 }}
                          lines="full"
                        >
                          {entry.SerialTrackingFlag ? (
                            <IonInput
                              value={entry.SystemQty}
                              disabled
                            ></IonInput>
                          ) : (
                            <IonInput
                              inputMode="decimal"
                              type="number"
                              onIonChange={(ev) => {
                                if (!ev.target.value) return;
                                setCountValue(Number(ev.target.value), index);
                              }}
                            ></IonInput>
                          )}
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            );
          })}
      </IonContent>
    </IonPage>
  );
};

export default CycleCountEntry;
