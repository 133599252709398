import { IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { Route } from "react-router-dom";
import Menu from "./components/Menu";
import WorkOrders from "./pages/WorkOrders";
import SettingsTabs from "./pages/SettingsTabs";
import WorkOrderOverview from "./pages/WorkOrderOverview";
import WorkOrderDetails from "./pages/WorkOrderDetails";
import Home from "./pages/Home";
import TabPage from "./pages/TabPage";
import PermitsTab from "./components/PermitsTab";
import InstructionsTab from "./components/InstructionsTab";
import ResourcesTab from "./components/ResourcesTab";
import Equipment from "./pages/Equipment";
import Inventory from "./pages/Inventory";
import RemarksTab from "./components/Remarks";
import DocumentsTab from "./components/DocumentsTab";
import LaborActivityTab from "./components/LaborActivityTab";
import MeterReadingHistory from "./components/MeterReadingHistory";
import LongDescriptionTab from "./components/LongDescriptionTab";
import MetersTabPage from "./pages/MetersTabPage";
import Meters from "./components/Meters";
import QuickWorkOrder from "./pages/QuickWorkOrder";
import OpenWorkOrders from "./pages/OpenWorkOrders";
import CreateQuickWO from "./pages/CreateQuickWO";
import Inspection from "./pages/Inspection";
import AnalysisCodes from "./components/AnalysisCodes";
import CompletionData from "./components/CompletionData";
import {
  TranslationMessagesContext,
  TranslationsContext,
  getTranslatedMessages,
  getTranslations,
} from "./util/Translations";
import { useContext, useEffect } from "react";
import MyInspections from "./pages/MyInspections";
import RequesterWOOverview from "./pages/Requester/RequesterWOOverview";
import MaterialIssue from "./pages/MaterialIssue";
import Logout from "./pages/Logout";
import { loadPlantCurrency } from "./util/PlantHelper";
import { loadUserSettings } from "./util/UserHelper";
import Requisitions from "./pages/Requisitions";
import IssueReturn from "./pages/IssueReturn";
import Stores from "./pages/Stores";
import { AuthContext } from "./util/OAuth";
import BarcodeScannerHeader from "./components/BarcodeScannerHeader";
import BinTransfer from "./components/BinTransfer";
import CycleCount from "./components/CycleCount";
import ItemAdjustment from "./components/ItemAdjustment";
import EquipmentOverview from "./pages/EquipmentOverview";
import { getApiSession } from "./util/ApiOptionsHelper";
import EquipmentHierarchy from "./pages/EquipmentHierarchy";
import WOApproval from "./pages/WOApproval";
import RequisitionApproval from "./pages/RequisitionApproval";
import CycleCountEntry from "./components/CycleCountEntry";

const AppTabs: React.FC = () => {
  const { translations, setTranslations } = useContext(TranslationsContext);
  const { setTranslatedMessages } = useContext(TranslationMessagesContext);
  const { setLoggedIn } = useContext(AuthContext);

  const loadData = async () => {
    const translations = await getTranslations();
    setTranslations && setTranslations(translations);
    const translatedMessages = await getTranslatedMessages();
    setTranslatedMessages && setTranslatedMessages(translatedMessages);
    //load user settings
    await loadUserSettings();
    setLoggedIn && setLoggedIn(true);
    await loadPlantCurrency();
  };

  useEffect(() => {
    getApiSession().then((apiSession) => {
      if (apiSession && apiSession.LicenseSessionId) {
        loadData();
      }
    });
  }, []);

  return (
    <IonSplitPane contentId="main">
      <BarcodeScannerHeader />
      <Menu />
      <IonRouterOutlet id="main">
        <Route path="/home" component={Home} exact />
        <Route path="/MyInspections" component={MyInspections} exact />
        <Route path="/Home" component={Home} exact />
        <Route path="/WorkOrders" component={WorkOrders} exact />
        <Route path="/Equipment" component={Equipment} exact />
        <Route path="/Inventory" component={Inventory} exact />
        <Route path="/Settings" component={SettingsTabs} />
        <Route path="/logout" component={Logout} exact />
        <Route
          path="/workorder/:workOrderId"
          component={WorkOrderOverview}
          exact
        />
        <Route
          path="/workorder/:workOrderId/details"
          render={() => (
            <TabPage
              title={translations["lbl_grp_wo_details"] || "Work Order Details"}
              content={<WorkOrderDetails />}
            />
          )}
        />
        <Route
          path="/workorder/:workOrderId/long-description"
          render={() => (
            <TabPage
              title={translations["lbl_longdescription"] || "Long Description"}
              content={<LongDescriptionTab />}
            />
          )}
        />
        <Route
          path="/workorder/:workOrderId/resources"
          render={() => (
            <TabPage
              title={translations["lbl_resources"] || "Resources"}
              content={<ResourcesTab />}
            />
          )}
        />
        <Route
          path="/workorder/:workOrderId/instructions"
          render={() => (
            <TabPage
              title={translations["lbl_instructions"] || "Instructions"}
              content={<InstructionsTab />}
            />
          )}
        />
        <Route
          path="/workorder/:workOrderId/permits"
          render={() => (
            <TabPage
              title={translations["lbl_permits"] || "Permits"}
              content={<PermitsTab />}
            />
          )}
        />
        <Route
          path="/workorder/:workOrderId/documents"
          render={() => (
            <TabPage
              title={translations["lbl_documents"] || "Documents"}
              content={<DocumentsTab />}
            />
          )}
        />
        <Route
          path="/workorder/:workOrderId/labor-activity"
          exact
          strict
          render={() => (
            <TabPage
              title={translations["lbl_labor_activity"] || "Labor Activity"}
              content={<LaborActivityTab />}
            />
          )}
        />
        <Route
          path="/workorder/:workOrderId/remarks/:wOrderDate/:wOrderTime"
          render={() => (
            <TabPage
              title={translations["lbl_category_comments"] || "Comments"}
              content={<RemarksTab />}
            />
          )}
        />
        <Route
          path="/workorder/:workOrderId/meters"
          exact
          render={() => (
            <TabPage
              title={translations["lbl_category_meter"] || "Meter"}
              content={<Meters />}
            />
          )}
        />
        <Route
          path="/workorder/:workOrderId/meterReadingHistory/:meter"
          exact
          render={() => (
            <MetersTabPage
              title={translations["lbl_mr_history"] || "Meter Reading History"}
              content={<MeterReadingHistory />}
            />
          )}
        />
        <Route
          path="/workorder/:workOrderId/analysiscodes"
          exact
          render={() => (
            <TabPage
              title={translations["lbl_analysis_codes"] || "Analysis Codes"}
              content={<AnalysisCodes />}
            />
          )}
        />
        <Route
          path="/workorder/:workOrderId/completiondata"
          exact
          render={() => (
            <TabPage
              title={
                translations["lbl_grp_completion_details"] || "Completion Data"
              }
              content={<CompletionData />}
            />
          )}
        />
        <Route
          path="/workorder/:workOrderId/issuematerial"
          exact
          component={MaterialIssue}
        />
        <Route
          path="/workorder/:workOrderId/returnmaterial"
          exact
          component={IssueReturn}
        />
        <Route
          path="/quick-work-order/equipment"
          component={QuickWorkOrder}
          exact
        />
        <Route
          path="/quick-work-order/equipment/:equipId"
          exact
          component={OpenWorkOrders}
        />
        <Route
          path={[
            "/quick-work-order/equipment/:equipId/create",
            "/quick-work-order/equipment/:equipId/:workOrderId/create",
          ]}
          exact
          component={CreateQuickWO}
        />
        <Route
          path="/workorder/:workOrderId/inspection/:inspectionId"
          component={Inspection}
        />
        <Route
          path="/requester/workorder/:workOrderId"
          component={RequesterWOOverview}
          exact
        />
        <Route
          path="/requester/workorder/:workOrderId/details"
          render={() => (
            <TabPage
              title={translations["lbl_grp_wo_details"] || "Work Order Details"}
              content={<WorkOrderDetails />}
            />
          )}
        />
        <Route
          path="/requester/workorder/:workOrderId/long-description"
          render={() => (
            <TabPage
              title={translations["lbl_longdescription"] || "Long Description"}
              content={<LongDescriptionTab />}
            />
          )}
        />
        <Route
          path="/requester/workorder/:workOrderId/documents"
          render={() => (
            <TabPage
              title={translations["lbl_documents"] || "Documents"}
              content={<DocumentsTab />}
            />
          )}
        />
        <Route
          path="/requester/workorder/:workOrderId/remarks/:wOrderDate/:wOrderTime"
          render={() => (
            <TabPage
              title={translations["lbl_category_comments"] || "Comments"}
              content={<RemarksTab />}
            />
          )}
        />
        <Route
          path="/requester/create-request/equipment"
          exact
          component={QuickWorkOrder}
        />
        <Route
          path="/requester/create-request/equipment/:equipId"
          exact
          component={OpenWorkOrders}
        />
        <Route
          path={"/requester/create-request/equipment/:equipId/create"}
          exact
          component={CreateQuickWO}
        />
        <Route path="/requester/work-orders" exact component={WorkOrders} />
        <Route path="/Stores" exact component={Stores} />
        <Route path="/Stores/Requisitions" component={Requisitions} exact />
        <Route
          path="/Stores/Requisitions/:storesTab"
          component={Requisitions}
          exact
        />
        <Route
          path="/Stores/Requisitions/:requisitionId/issue"
          component={MaterialIssue}
          exact
        />
        <Route
          path="/Stores/Requisitions/:requisitionId/return"
          component={IssueReturn}
          exact
        />
        <Route
          path={"/Stores/Accounts/:accountSelections/issue"}
          component={MaterialIssue}
          exact
        />
        <Route
          path={"/Stores/Accounts/:accountSelections/return"}
          component={IssueReturn}
          exact
        />
        <Route path={"/BinTransfer"} component={BinTransfer} exact />
        <Route path={"/BinTransfer/:itemId"} component={BinTransfer} exact />
        <Route path={"/CycleCount"} component={CycleCount} exact />
        <Route
          path={"/CycleCount/:batchId"}
          component={CycleCountEntry}
          exact
        />
        <Route path={"/ItemAdjustment"} component={ItemAdjustment} exact />
        <Route
          path={"/ItemAdjustment/:itemId"}
          component={ItemAdjustment}
          exact
        />
        <Route
          path="/Equipment/:equipmentId"
          component={EquipmentOverview}
          exact
        />
        <Route
          path="/Equipment/:equipmentId/hierarchy"
          exact
          component={EquipmentHierarchy}
        />
        <Route path="/WOApproval" exact component={WOApproval} />
        <Route
          path="/RequisitionApproval"
          exact
          component={RequisitionApproval}
        />
      </IonRouterOutlet>
    </IonSplitPane>
  );
};

export default AppTabs;
