export const translatedValues = () => {
  return [
    { MessageId: "NoResultsFound", MessageParameters: [] },
    { MessageId: "ChangeDatabasePlant", MessageParameters: [] },
    { MessageId: "ErrDocViewing", MessageParameters: [] },
    { MessageId: "ErrDocRetrieving", MessageParameters: [] },
    { MessageId: "SelectedEquip", MessageParameters: [] },
    { MessageId: "Continue", MessageParameters: [] },
    { MessageId: "DisplayFirst", MessageParameters: [] },
    { MessageId: "ErrFetchingWOInst", MessageParameters: [] },
    { MessageId: "ErrFetchingWOLabor", MessageParameters: [] },
    { MessageId: "ErrFetchingLabor", MessageParameters: [] },
    { MessageId: "ErrDel", MessageParameters: [] },
    { MessageId: "TimeEntryDeleteSuccess", MessageParameters: [] },
    { MessageId: "DeleteLabor", MessageParameters: [] },
    { MessageId: "ErrLongDesc", MessageParameters: [] },
    { MessageId: "Offline", MessageParameters: [] },
    { MessageId: "ErrPermits", MessageParameters: [] },
    { MessageId: "ErrRetrievingComment", MessageParameters: [] },
    { MessageId: "ErrFetchingRes", MessageParameters: [] },
    { MessageId: "ErrFetchingWORes", MessageParameters: [] },
    { MessageId: "SubmitTime", MessageParameters: [] },
    { MessageId: "SessionExpired", MessageParameters: [] },
    { MessageId: "ValUser", MessageParameters: [] },
    { MessageId: "LoginFailed", MessageParameters: [] },
    { MessageId: "ErrRetrievingFields", MessageParameters: [] },
    { MessageId: "CompInspExist", MessageParameters: [] },
    { MessageId: "CompInsp", MessageParameters: [] },
    { MessageId: "NoChangesDBPlant", MessageParameters: [] },
    { MessageId: "CacheCleared", MessageParameters: [] },
    { MessageId: "ActivitySaveSuccess", MessageParameters: [] },
    { MessageId: "ErrFetchingLabor", MessageParameters: [] },
    { MessageId: "ErrFetchingCraftList", MessageParameters: [] },
    { MessageId: "ErrFetchingTheRateList", MessageParameters: [] },
    { MessageId: "ErrCustomFields", MessageParameters: [] },
    { MessageId: "ErrCustomValues", MessageParameters: [] },
    { MessageId: "WOCreateFailed", MessageParameters: [] },
    { MessageId: "DescriptionSaveSuccess", MessageParameters: [] },
    { MessageId: "DescriptionSaveError", MessageParameters: [] },
    { MessageId: "CommentSaveSuccess", MessageParameters: [] },
    { MessageId: "CommentSaveError", MessageParameters: [] },
    { MessageId: "ErrorReadingTimeEntries", MessageParameters: [] },
    { MessageId: "ConfirmDelete", MessageParameters: [] },
    { MessageId: "NoAttachedDocuments", MessageParameters: [] },
    { MessageId: "QWColumnMissing", MessageParameters: [] },
    { MessageId: "BinTransferSuccess", MessageParameters: [] },
    { MessageId: "ItemSavedSuccesfully", MessageParameters: [] },
    { MessageId: "NegativeQtyOnHandWarning", MessageParameters: [] },
    { MessageId: "NegativeQtyOnHandError", MessageParameters: [] },
    { MessageId: "ICFailureFlagMessage", MessageParameters: [] },
  ];
};
