import { HttpMethods, sendRequest } from "../util/ApiHelper";

const CONTROLLER_NAME = "WorkOrders";

export interface WorkOrderParams {
  woNumber: string;
  appName?: string;
  activity?: boolean;
  comment?: boolean;
  equipmentMaster?: boolean;
  events?: boolean;
  inspectionDefinition?: boolean;
  instruction?: boolean;
  labor?: boolean;
  permits?: boolean;
  resources?: boolean;
  scheduledAssignments?: boolean;
  scheduledCrafts?: boolean;
  costPosting?: boolean;
  translations?: boolean;
}

export interface GetUdvListParams {
  woNumber: string;
  category?: string;
  subCategory?: string;
}

export interface GetTemplateForWindowParams {
  equipment: string;
  windowName: string;
  woType: string;
}

export interface GetTemplateForWorkOrderParams {
  equipment: string;
  woNumber: string;
  woType: string;
  copyValue?: string;
}

export interface PutPartialWorkOrderParams {
  WorkOrderNumber: string;
  Updates: [
    {
      PropertyName: string;
      TableName: string;
      ColumnName: string;
      DataValue: any;
      AppendMode: true;
      AppendType: string;
      UserDateTime: string;
    }
  ];
}

export const getWorkOrder = async (params: WorkOrderParams) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/Get`,
    undefined,
    {
      woNumber: params.woNumber,
      appName: params.appName ?? "Mobile",
      activity: !!params.activity ? "true" : "false",
      comment: !!params.comment ? "true" : "false",
      equipmentMaster: !!params.equipmentMaster ? "true" : "false",
      events: !!params.events ? "true" : "false",
      inspectionDefinition: !!params.inspectionDefinition ? "true" : "false",
      instruction: !!params.instruction ? "true" : "false",
      labor: !!params.labor ? "true" : "false",
      permits: !!params.permits ? "true" : "false",
      resources: !!params.resources ? "true" : "false",
      scheduledAssignments: !!params.scheduledAssignments ? "true" : "false",
      scheduledCrafts: !!params.scheduledCrafts ? "true" : "false",
      costPosting: !!params.costPosting ? "true" : "false",
      translations: !!params.translations ? "true" : "false",
    }
  );
};

export const getLinkedDocInfoList = async (woNumber: string) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/GetLinkedDocInfoList`,
    undefined,
    { woNumber: woNumber }
  );
};

export const completeWorkOrder = async (data: any) => {
  return await sendRequest(
    HttpMethods.POST,
    `${CONTROLLER_NAME}/CompleteWorkOrder`,
    data
  );
};

export const getUdvList = async (params: GetUdvListParams) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/GetUdvList`,
    undefined,
    {
      woNumber: params.woNumber,
    }
  );
};

export const getCustomElementColumnDetailList = async () => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/GetCustomElementColumnDetailList`
  );
};

export const checkUpdateWorkOrder = async (data: any) => {
  return await sendRequest(
    HttpMethods.POST,
    `${CONTROLLER_NAME}/CheckUpdateWorkOrder`,
    data
  );
};

export const updateWorkOrder = async (data: any) => {
  return await sendRequest(
    HttpMethods.POST,
    `${CONTROLLER_NAME}/UpdateWorkOrder`,
    data
  );
};

export const createViaCopy = async (data: any) => {
  return await sendRequest(
    HttpMethods.POST,
    `${CONTROLLER_NAME}/CreateViaCopy`,
    data
  );
};

export const getTemplateForWindow = async (
  data: GetTemplateForWindowParams
) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/GetTemplateForWindow`,
    undefined,
    data
  );
};

export const getTemplateForWorkOrder = async (
  data: GetTemplateForWorkOrderParams
) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/GetTemplateForWorkOrder`,
    undefined,
    data
  );
};

export const putPartialWorkOrder = async (data: PutPartialWorkOrderParams) => {
  return await sendRequest(
    HttpMethods.PUT,
    `${CONTROLLER_NAME}/PutPartialWorkOrder`,
    data
  );
};

export const findWorkOrder = async (searchTerm: string) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/Find`,
    undefined,
    {
      includeCompleted: "false",
      searchTerm: searchTerm,
    }
  );
};

export const getCustomColumnsWithGroup = async (windowName?: string) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/GetCustomColumnsWithGroup`,
    undefined,
    !!windowName && {
      windowName: windowName,
    }
  );
};
