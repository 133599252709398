import { CapacitorHttp } from "@capacitor/core";
import { ApiOptionsData, ApiSession } from "../models/api/ApiOptions";
import {
  API_BASE_ADDRESS,
  MOBILE_BOOTSTRAP_API_BASE_ADDRESS,
} from "./Constants";
import { getPortalBearerToken } from "./OAuth";
import { get, remove, set } from "./Preferences";
import authConfig from "../authConfig.json";

const _apiOptionsDataKey = "ApiOptionsData";
const _apiSessionKey = "ApiSession";
const _qrPlantDB = "QRPlantDB";

export interface QRPlantDB {
  DBName: string;
  PlantCode: string;
}

export const getApiSession = async () => {
  let apiSession = (await get(_apiSessionKey)) as ApiSession;
  //return valid value, if undefined or null
  if (apiSession === undefined) {
    apiSession = {
      BackendApiUrl: "",
      DatabaseName: "",
      LicenseExpiration: 0,
      LicenseIssuedAt: 0,
      LicenseSessionId: "",
      PlantCode: "",
      B2CUserFlowScope: "",
    };
  }
  //update api session with QRPlantDB if available
  return await updateApiSessionWithQRPlantDB(apiSession);
};

export const setApiSession = async (apiSession: ApiSession) => {
  return await set(_apiSessionKey, apiSession);
};

export const clearApiSession = async () => {
  await remove(_apiSessionKey);
};

export const getApplication = async (dbName: string) => {
  let apiOptionsData = await getApiOptionsData();
  const application = apiOptionsData.Applications.find((app) => {
    return app.DatabaseName === dbName;
  });
  return application !== undefined ? application : null;
};

export const getPlant = async (pCode: string) => {
  let apiOptionsData = await getApiOptionsData();
  const plant = apiOptionsData.Plants.find((plant) => {
    return plant.PlantCode === pCode;
  });
  return plant !== undefined ? plant : null;
};

export const getCurrentPlant = async () => {
  const apiSession = await getApiSession();
  return !!apiSession ? apiSession.PlantCode : "";
};

export const getApiOptionsData = async (): Promise<ApiOptionsData> => {
  return (await get(_apiOptionsDataKey)) as ApiOptionsData;
};

export const clearApiOptionsData = async () => {
  await remove(_apiOptionsDataKey);
};

export const mobileBootstrap = async () => {
  const portalBearerToken = await getPortalBearerToken();
  const response = await CapacitorHttp.get({
    method: "GET",
    url: `${MOBILE_BOOTSTRAP_API_BASE_ADDRESS}Mobile/MobileBootstrap`,
    headers: {
      authorization: `Bearer ${portalBearerToken}`,
    },
  });
  if (response.status === 200) {
    const apiOptionsData = createApiOptionsData(response.data);
    await set(_apiOptionsDataKey, apiOptionsData);
  } else {
    console.log("Mobilebootstrap error: ", response.data);
  }
};

export const getQRPlantDB = async () => {
  return (await get(_qrPlantDB)) as QRPlantDB;
};

export const setQRPlantDB = async (qrPlantDB: QRPlantDB) => {
  if (!!qrPlantDB) {
    await set(_qrPlantDB, qrPlantDB);
  }
};

export const clearQRPlantDB = async () => {
  await remove(_qrPlantDB);
};

export const clearSiteData = async () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
};

const createApiOptionsData = (resposneData: any) => {
  let apiOptionsData: ApiOptionsData = {
    Applications: [],
    Plants: [],
  };
  if (resposneData.applications) {
    resposneData.applications.forEach((app: any) => {
      const backendApiUrl: string = !!app.backendApiUrl
        ? app.backendApiUrl
        : API_BASE_ADDRESS;
      apiOptionsData.Applications.push({
        BackendApiUrl: backendApiUrl.endsWith("/")
          ? backendApiUrl
          : backendApiUrl + "/",
        DatabaseName: app.databaseName,
        Description: app.description,
        ProductionDatabaseFlag: app.productionDatabaseFlag,
        B2CUserFlowScope: !!app.b2CUserFlowScope
          ? app.b2CUserFlowScope.join(" ")
          : authConfig.SCOPE,
      });
    });
  }
  if (resposneData.plants) {
    resposneData.plants.forEach((plant: any) => {
      apiOptionsData.Plants.push({
        Description: plant.description,
        PlantCode: plant.plantCode,
      });
    });
  }
  return apiOptionsData;
};

const updateApiSessionWithQRPlantDB = async (apiSession: ApiSession) => {
  const qrPlantDB = await getQRPlantDB();
  if (!!qrPlantDB) {
    const plant = await getPlant(qrPlantDB.PlantCode);
    const application = await getApplication(qrPlantDB.DBName);
    if (!!plant && !!application) {
      apiSession.PlantCode = plant.PlantCode;
      apiSession.DatabaseName = application.DatabaseName;
      apiSession.BackendApiUrl = application.BackendApiUrl;
      apiSession.B2CUserFlowScope = application.B2CUserFlowScope;

      //update stored api session
      await setApiSession(apiSession);
      //clear qr plant/db
      clearQRPlantDB();
    }
  }
  return apiSession;
};
