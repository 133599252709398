import { IonCard, IonLabel, IonLoading, IonToast } from "@ionic/react";
import { useContext, useState } from "react";
import { QueryResult } from "../hooks/useQueryExec";
import { TranslationMessagesContext } from "../util/Translations";
import CardInfo from "./CardInfo";
import CardFilter from "./CardFilter";
import WOApprovalModal from "./WOApprovalModal";
import {
  Requisition,
  WorkOrder,
  WorkOrderActivity,
  WorkOrderMetadata,
} from "../models/workorders/WorkOrder";
import { WorkOrderParams, getWorkOrder } from "../api/WorkOrders";
import { ApiResponse } from "../models/api/ApiResponse";
import ReqApprovalModal from "./ReqApprovalModal";
import { getRequisitionLines, getReviewers } from "../api/MobileApproval";
import { getCurrentPlant } from "../util/ApiOptionsHelper";
import { getUserId } from "../util/UserHelper";

interface ApprovalResultsParams {
  queryRows: QueryResult[];
  errorState: string;
  totalCount: number;
  showLoading: boolean;
  setShowLoading: (showLoading: boolean) => void;
  filterRows: QueryResult[];
  filterMethod: (queryString: string) => Promise<void>;
  filterString: string;
  metadata: WorkOrderMetadata;
  displayApprovalResults: () => Promise<void>;
  approvalName: string;
}

const ApprovalResults: React.FC<ApprovalResultsParams> = (props) => {
  const {
    queryRows,
    errorState,
    totalCount,
    showLoading,
    setShowLoading,
    filterRows,
    filterMethod,
    filterString,
    metadata,
    displayApprovalResults,
    approvalName,
  } = props;
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const [approvalModal, setApprovalModal] = useState(false);
  const [cardHeader, setCardHeader] = useState<string>("");
  const [workOrder, setWorkOrder] = useState<WorkOrder>();
  const [requisition, setRequisition] = useState<Requisition[]>();
  const [woActivity, setWoActivity] = useState<WorkOrderActivity[]>();
  const [description, setDescription] = useState<string>("");
  const [longDescription, setLongDescription] = useState<string>("");
  const [nextReviewer, setNextReviewer] = useState<string>("");
  const [assignedTo, setAssignedTo] = useState<string>("");
  const [approveToast, setApproveToast] = useState(false);
  const [reviewToast, setReviewToast] = useState(false);
  const [rejectToast, setRejectToast] = useState(false);
  const [statusMessage, setStatusMessage] = useState<string>("");

  const approvalModalCancel = () => {
    setApprovalModal(false);
  };

  const isApproved = async (status: string) => {
    setStatusMessage(status);
    setApproveToast(true);
    setApprovalModal(false);
    displayApprovalResults();
  };

  const isReviewed = async (status: string) => {
    setStatusMessage(status);
    setReviewToast(true);
    setApprovalModal(false);
    displayApprovalResults();
  };

  const isRejected = async (status: string) => {
    setStatusMessage(status);
    setRejectToast(true);
    setApprovalModal(false);
    displayApprovalResults();
  };

  const getWorkOrderQuery = async (header: string) => {
    setShowLoading(true);
    const woParams: WorkOrderParams = {
      woNumber: header,
      activity: true,
      comment: true,
    };
    getWorkOrder(woParams).then((response) => {
      setWorkOrder(response.data);
      getWOActivity(response);
      getWODescription(response);
      getLongDescription(response);
      getAssignedTo(response);
      setShowLoading(false);
    });
  };

  const getReqQuery = async (header: string) => {
    setShowLoading(true);
    getRequisitionLines(header).then((response) => {
      setRequisition(response.data?.Data);
      setShowLoading(false);
    });
  };

  const handleQuery = async (header: string) => {
    getNextReviewer();
    approvalName === "WO" ? getWorkOrderQuery(header) : getReqQuery(header);
  };

  const getWOActivity = async (response: ApiResponse) => {
    setWoActivity(response.data?.Activity);
  };

  const getWODescription = async (response: ApiResponse) => {
    setDescription(response.data?.Description);
  };

  const getLongDescription = async (response: ApiResponse) => {
    setLongDescription(response.data?.Comment?.LongDescription);
  };

  const getNextReviewer = async () => {
    const httpParams = {
      plant: await getCurrentPlant(),
      user: await getUserId(),
      type: approvalName === "Req" ? 1 : 2,
    };
    getReviewers(httpParams).then((response) => {
      setNextReviewer(response.data?.DefaultReviewer);
    });
  };

  const getAssignedTo = async (response: ApiResponse) => {
    setAssignedTo(response.data?.AssignedTo);
  };

  return (
    <>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      <IonToast
        isOpen={approveToast || reviewToast || rejectToast}
        onDidDismiss={() => {
          setApproveToast(false);
          setReviewToast(false);
          setRejectToast(false);
        }}
        message={statusMessage}
        duration={2000}
        position="top"
      ></IonToast>
      {errorState ? (
        <div style={{ padding: 8 }} hidden={errorState === ""}>
          <IonLabel color={"danger"}>{errorState}</IonLabel>
        </div>
      ) : (
        <div style={{ padding: 8 }} hidden={showLoading || totalCount > 0}>
          <IonLabel color={"warning"}>
            {translatedMessages["NoResultsFound"]?.MessageText ||
              "No results found."}
          </IonLabel>
        </div>
      )}

      {!!queryRows && queryRows.length > 0 && (
        <>
          <CardFilter
            queryRows={queryRows}
            resultsFilter={filterString}
            filterMethod={filterMethod}
          />
          {filterRows.map(function (row) {
            return (
              <IonCard
                key={row.HeaderRow}
                onClick={() => {
                  setCardHeader(row.HeaderValue);
                  handleQuery(row.HeaderValue);
                  setApprovalModal(true);
                }}
              >
                <CardInfo header={row.HeaderValue} columns={row.Columns} />
              </IonCard>
            );
          })}
        </>
      )}
      {approvalModal &&
        (workOrder && woActivity ? (
          <WOApprovalModal
            isOpen={approvalModal}
            cancel={approvalModalCancel}
            approve={isApproved}
            review={isReviewed}
            reject={isRejected}
            cardHeader={cardHeader}
            metadata={metadata}
            workOrder={workOrder}
            woActivity={woActivity}
            description={description}
            longDescription={longDescription}
            nextReviewer={nextReviewer}
            setNextReviewer={setNextReviewer}
            assignedTo={assignedTo}
            setAssignedTo={setAssignedTo}
            setShowLoading={setShowLoading}
          ></WOApprovalModal>
        ) : requisition ? (
          <ReqApprovalModal
            isOpen={approvalModal}
            cancel={approvalModalCancel}
            approve={isApproved}
            review={isReviewed}
            reject={isRejected}
            cardHeader={cardHeader}
            metadata={metadata}
            requisition={requisition}
            setRequisition={setRequisition}
            nextReviewer={nextReviewer}
            setNextReviewer={setNextReviewer}
            setShowLoading={setShowLoading}
          ></ReqApprovalModal>
        ) : (
          <></>
        ))}
    </>
  );
};

export default ApprovalResults;
