import React, { useContext, useEffect, useState } from "react";
import { ColumnsWithGroupData, getColumnsWithGroup } from "../api/Windows";
import { GetColumnsWithGroupResponse } from "../models/windows/GetColumnsWithGroup";
import MaterialIssueDetails from "../components/MaterialIssueDetails";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { useParams } from "react-router";
import { decodeParam } from "../util/ApiHelper";
import MaterialIssueItemList from "../components/MaterialIssueItemList";
import { add, chevronBackOutline } from "ionicons/icons";
import OnlineStatus from "../components/OnlineStatus";
import {
  BinData,
  MaterialIssueListItem,
  PlannedListItem,
} from "../models/materialissue/MaterialIssue";
import MaterialIssueItemSearch from "../components/MaterialItemSearch";
import {
  MaterialIssueBody,
  getPlannedList,
  issueMaterials,
} from "../api/Materials";
import { useIssueReturnManager } from "../hooks/useIssueReturnManager";
import {
  matchesAvailableItemsNotFullyIssued,
  matchesItemNotFullyIssued,
} from "../util/IssueMaterialHelper";
import { TranslationMessagesContext } from "../util/Translations";

export interface ItemListEntry {
  item: PlannedListItem;
  checked: boolean;
  binData: BinData[];
  issued: boolean;
  errorMessage?: string;
  isAdhoc: boolean;
  comments: string;
}

export enum PlannedListFilterType {
  All = "Display All",
  ItemsNotFullyIssued = "Display Items Not Fully Issued",
  AvailableItemsNotFullyIssued = "Display Available Items Not Fully Issued",
}

export const retrieveAccountSelections = (acc: string) => {
  if (!acc) {
    return undefined;
  }
  let decodedSelections = decodeParam(acc);
  let accObject = {} as any;
  let keys = [] as string[];
  let vals = [] as string[];
  decodedSelections.split("+").forEach((val, index) => {
    if (index % 2 === 0) {
      keys.push(val);
    } else {
      vals.push(val);
    }
  });
  keys.forEach((key, index) => {
    accObject[key] = vals[index];
  });
  return accObject;
};

const MaterialIssue: React.FC = () => {
  const { workOrderId, requisitionId } = useParams<{
    workOrderId: string;
    requisitionId: string;
    accountSelections: string;
  }>();
  const {
    employees,
    employeeId,
    storeLocation,
    itemList,
    dateCharged,
    searchOpen,
    displayWo,
    displayReq,
    accountObject,
    translations,
    showLoading,
    negativeInvCtrlVal,
    setShowLoading,
    setEmployeeId,
    setStoreLocation,
    setItemList,
    setDateCharged,
    setSearchOpen,
    setupDetails,
    getBinData,
    getDefaultTransactionQuantity,
    onItemSelect,
    onSelectAll,
    onBinAmountChange,
    onResponse,
    onComment,
    onItemAdd,
  } = useIssueReturnManager();
  const [issueDetailsColumns, setIssueDetailsColumns] =
    useState<GetColumnsWithGroupResponse>();
  const [filter, setFilter] = useState<PlannedListFilterType>(
    PlannedListFilterType.ItemsNotFullyIssued
  );
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const negativeError =
    translatedMessages["NegativeQtyOnHandError"]?.MessageText ||
    "The current Bin Quantity is not sufficient to issue the chosen quantity of this item.";
  const [presentToast] = useIonToast();

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    if (storeLocation && (displayWo || displayReq)) {
      getPlannedListItems(storeLocation, displayWo, displayReq);
    }
  }, [storeLocation, displayWo, displayReq]);

  useEffect(() => {
    filterItems(filter);
  }, [filter]);

  const getIssueDetailsColumns = async () => {
    const data: ColumnsWithGroupData = {
      WindowName: "mob_inventory_issue_details",
      IncludeValidValues: "true",
    };
    const response = await getColumnsWithGroup(data);
    return response.data as GetColumnsWithGroupResponse;
  };

  const setup = async () => {
    setShowLoading(true);
    const issueDetailsColumns = await getIssueDetailsColumns();
    setIssueDetailsColumns(issueDetailsColumns);
    setupDetails();
    setShowLoading(false);
  };

  const filterItems = (filter: PlannedListFilterType) => {
    const uncheckFilteredItems = itemList.map((item) => {
      if (
        item.isAdhoc ||
        filter === PlannedListFilterType.All ||
        matchesItemNotFullyIssued(item, filter) ||
        matchesAvailableItemsNotFullyIssued(item, filter)
      ) {
        return item;
      }
      return { ...item, checked: false };
    });
    setItemList(uncheckFilteredItems);
  };

  const getPlannedListItems = async (
    storeLocation: string,
    workOrder: string,
    requisition: string
  ) => {
    const body = {
      requisition: requisition,
      storesLocation: storeLocation,
      workOrder: workOrder,
    };
    setShowLoading(true);
    const response = await getPlannedList(body);
    const items = await attachBinData(response.data?.MaterialList || [], false);
    setItemList(items);
    setShowLoading(false);
  };

  const attachBinData = async (data: PlannedListItem[], isAdhoc: boolean) => {
    const items: ItemListEntry[] = [];
    for (const item of data) {
      const binData = await getBinData(item.Item, item.StoresLocation);
      const binDataWithQuantity = getDefaultTransactionQuantity(item, binData);
      items.push({
        item: item,
        binData: binDataWithQuantity,
        checked: false,
        issued: false,
        isAdhoc,
        comments: "",
      });
    }
    return items;
  };

  const isReadyToIssue = () => {
    const checkedList = itemList.filter((entry) => {
      return entry.checked;
    });
    if (checkedList.length === 0) {
      return false;
    }
    let amount = 0;
    for (const entry of checkedList) {
      for (const bin of entry.binData) {
        // Do not enable the issue button if the plant setting is blocking negative values;
        if (
          bin.TransactionQuantity &&
          bin.TransactionQuantity > 0 &&
          bin.TransactionQuantity > bin.QtyOnHand &&
          negativeInvCtrlVal === "e"
        ) {
          return false;
        }
        amount += bin.TransactionQuantity || 0;
      }
    }
    return amount > 0;
  };

  const issue = async () => {
    if (!storeLocation || !employeeId) {
      return;
    }
    const materialIssueList: MaterialIssueListItem[] = [];
    const selectedItems = itemList.filter((entry) => {
      return entry.checked;
    });
    selectedItems.forEach((entry, index) => {
      const bins = [];
      for (const bin of entry.binData) {
        if (
          bin.TransactionQuantity &&
          bin.TransactionQuantity > 0 &&
          entry.item.LocationQuantityAvailable > 0 &&
          entry.item.LocationQuantityAvailable >= bin.TransactionQuantity
        ) {
          bins.push({
            Bin: bin.Bin,
            MaterialIssueId: bin.ObjectId,
            TransactionQuantity: bin.TransactionQuantity,
          });
        }
      }
      if (bins.length > 0) {
        materialIssueList.push({
          Comments: entry.comments,
          Item: entry.item.Item,
          ItemIndex: index,
          Requisition: entry.item.Requisition,
          RequisitionLine: entry.item.RequisitionLine,
          SerialIds: [],
          StoresLocation: storeLocation,
          Bins: bins,
        });
      } else {
        presentToast({
          message: negativeError,
          duration: 5000,
          position: "top",
        });
      }
    });

    const body: MaterialIssueBody = {
      PlanningRequisition: !!requisitionId ? displayReq : "",
      WorkOrder: !!workOrderId ? displayWo : "",
      IssueDate: dateCharged,
      IssueToUser: employeeId.EmployeeId,
      Account: {
        Account:
          !!accountObject && !!accountObject.Account
            ? accountObject.Account
            : "",
        Area: !!accountObject && !!accountObject.Area ? accountObject.Area : "",
        Department:
          !!accountObject && !!accountObject.Department
            ? accountObject.Department
            : "",
        Project:
          !!accountObject && !!accountObject.Project
            ? accountObject.Project
            : "",
      },
      MaterialIssueList: materialIssueList,
    };

    setShowLoading(true);
    const response = await issueMaterials(body);
    setShowLoading(false);
    if (response.isError) {
      presentToast({
        message: String(response.data),
        duration: 5000,
        color: "error",
      });
    } else {
      onResponse(response.data);
    }
  };

  const onFilterChange = (newFilter: PlannedListFilterType) => {
    if (newFilter === filter) {
      return;
    }
    setFilter(newFilter);
  };

  const onItemRemoval = (item: PlannedListItem) => {
    let itemIndex = itemList.findIndex((entry) => {
      return entry.item === item;
    });
    if (itemIndex === -1) {
      return;
    }
    let temp = [...itemList];
    temp.splice(itemIndex, 1);
    setItemList(temp);
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/home" icon={chevronBackOutline} />
            </IonButtons>
            <IonTitle>
              {displayWo || displayReq || accountObject.Account}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                disabled={!isReadyToIssue()}
                onClick={issue}
              >
                {translations["lbl_btn_issue"] || "Issue"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <OnlineStatus />
        </IonHeader>

        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          duration={5000}
        />

        <IonContent class="ion-padding">
          {issueDetailsColumns && (
            <MaterialIssueDetails
              columns={issueDetailsColumns}
              storeLocation={storeLocation}
              setStoreLocation={setStoreLocation}
              employeeId={employeeId}
              setEmployeeId={setEmployeeId}
              employees={employees}
              date={dateCharged}
              setDate={setDateCharged}
              mode="issue"
              displayWo={displayWo}
              displayReq={displayReq}
            />
          )}

          <div className="ion-margin-bottom"></div>

          <MaterialIssueItemList
            itemList={itemList}
            onSelect={onItemSelect}
            onSelectAll={onSelectAll}
            onBinAmountChange={onBinAmountChange}
            mode="issue"
            onFilterChange={onFilterChange}
            handleComment={onComment}
            currentFilter={filter}
            handleRemoval={!!accountObject ? onItemRemoval : undefined}
            negativeInvCtrlVal={negativeInvCtrlVal}
          />

          <IonFab slot="fixed" vertical="bottom" horizontal="end">
            <IonFabButton
              onClick={() => {
                setSearchOpen(true);
              }}
              disabled={!storeLocation}
            >
              <IonIcon icon={add}></IonIcon>
            </IonFabButton>
          </IonFab>
        </IonContent>
      </IonPage>
      {searchOpen && (
        <MaterialIssueItemSearch
          isOpen={searchOpen}
          onSelect={onItemAdd}
          onCancel={() => {
            setSearchOpen(false);
          }}
          storeLocation={storeLocation}
          issueOrReturn="issue"
        />
      )}
    </>
  );
};

export default MaterialIssue;
