import { useState } from "react";
import { getInventoryControl } from "../api/PlantSetups";
import { getUserSetups } from "../api/UserSetups";
import { findStoreItem, getItemLocations } from "../api/ItemLocations";
import { BinInfo, SearchResult } from "../models/materialissue/MaterialIssue";
import { getUserSettings } from "../util/UserHelper";
import { useParams } from "react-router";
import { decodeParam } from "../util/ApiHelper";

export function useStoresManager() {
  const [multiWarehouseCheck, setMultiWarehouseCheck] =
    useState<boolean>(false);
  const [itemAdjPermission, setItemAdjPermission] = useState<boolean>(false);
  const [isCommentsRequired, setIsCommentsRequired] = useState<boolean>();
  const { itemId } = useParams<{ itemId: string }>();
  const itemFromInventory = decodeParam(itemId);
  const [itemInput, setItemInput] = useState<string>();
  const [item, setItem] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [unitOfIssue, setUnitOfIssue] = useState<string>("");
  const [storeLocation, setStoreLocation] = useState<string>("");
  const [binInfo, setBinInfo] = useState<BinInfo[]>([]);
  const [results, setResults] = useState<SearchResult[]>([]);

  const checkMultiWarehouseOption = async () => {
    const userSettings = await getUserSettings();
    setMultiWarehouseCheck(userSettings?.ModulesInstalled.MultipleWarehousing);
  };

  const getDefaultStoreLocation = async () => {
    const httpParams = {
      userSection: "Inventory",
      userParameter: "stores_location",
    };
    const response = await getUserSetups(httpParams);
    return response.data?.UserValue as string | undefined;
  };

  const checkRequireComments = async () => {
    getInventoryControl().then((response) => {
      setIsCommentsRequired(response.data.AdjustQtyCommentFlag);
    });
  };

  const onStoreLocationSelect = async (value?: string) => {
    value && setStoreLocation(value);
  };

  const getGeneralInfo = async (item: string) => {
    setItem(item);
    getItemLocations({
      bin: true,
      item: item,
      storesLocation: !multiWarehouseCheck ? "main" : storeLocation,
    }).then((response) => {
      if (response.status === 200) {
        setDescription(response.data.Description);
        setUnitOfIssue(response.data.UnitOfIssue);
        setBinInfo(response.data.Bin);
      } else {
        setDescription("");
        setUnitOfIssue("");
      }
    });
  };

  const getSearchParamters = async (searchString: string) => {
    if (storeLocation && searchString && searchString.length > 2) {
      search(searchString, storeLocation);
    } else {
      setResults([]);
    }
  };

  const search = async (searchString: string, storeLocation: string) => {
    const params = {
      searchTerm: searchString,
      store: storeLocation,
    };
    const response = await findStoreItem(params);
    const searchResults = parseSearchResponse(response.data);
    setResults(await searchResults);
  };

  const parseSearchResponse = async (response: any) => {
    const results: SearchResult[] = [];
    const keys = Object.keys(response);
    const values = Object.values(response);
    for (let i = 0; i < keys.length; i++) {
      results.push({ item: keys[i], description: String(values[i] || "") });
    }
    return results;
  };

  const handleItemClick = async (item: string) => {
    setResults([]);
    getGeneralInfo(item);
  };

  const resetFields = async () => {
    setItem("");
    setItemInput("");
    setDescription("");
    setUnitOfIssue("");
    setBinInfo([]);
    setResults([]);
  };

  return {
    isCommentsRequired,
    item,
    description,
    unitOfIssue,
    binInfo,
    results,
    multiWarehouseCheck,
    storeLocation,
    itemAdjPermission,
    itemFromInventory,
    itemInput,
    setStoreLocation,
    setItemAdjPermission,
    setResults,
    search,
    checkMultiWarehouseOption,
    getDefaultStoreLocation,
    checkRequireComments,
    onStoreLocationSelect,
    getGeneralInfo,
    getSearchParamters,
    handleItemClick,
    resetFields,
    setItemInput,
  };
}
