import { CapacitorHttp } from "@capacitor/core";
import { getRequestApiUrl, getApiRequestHeaders } from "../util/ApiHelper";
import { getApiSession, setApiSession } from "../util/ApiOptionsHelper";
import { ABP_DOMAIN_HINT } from "../util/Constants";

//TODO: move to a common place later
declare global {
  var globalInitialized: boolean;
  var abpLoginInProgress: boolean;
  var abpRevalidateSession: boolean;
}

//initialize global values
if (!globalThis.globalInitialized) {
  globalThis.abpLoginInProgress = false;
  globalThis.abpRevalidateSession = false;
  globalThis.globalInitialized = true;
}

export const initializeAbpSession = async () => {
  if (!globalThis.abpLoginInProgress) {
    globalThis.abpLoginInProgress = true;
    const sessionValid = await abpLogin();
    globalThis.abpLoginInProgress = false;
    return sessionValid;
  }
  return { validLogin: true, message: '' }
};

export const abpLogout = async () => {
  const response = await Promise.all([getApiRequestHeaders(), getRequestApiUrl("abp/logout/")]).then(async (values) => {
    const headers = values[0];
    const url = values[1];

    const options = {
      url: url,
      headers: headers,
    };
    return await CapacitorHttp.post(options);
  });
  return response;
};

const abpLogin = async () => {
  const response = await Promise.all([getApiRequestHeaders(), getApiSession(), getRequestApiUrl("abp/login/")]).then(async (values) => {
    const headers = values[0];
    const apiSession = values[1];
    const url = values[2];

    const options = {
      url: url,
      headers: headers,
      data: {
        "extraLoginInfo":
          `domainHint=${ABP_DOMAIN_HINT}&database=${apiSession.DatabaseName}&plant=${apiSession.PlantCode}`,
      },
    }
    const response = await CapacitorHttp.post(options);
    //if session id is not returned assume invalid login or b2c-scope configuration
    if (response.status === 200 && !!response.headers["x-tw-licsession-id"]) {
      apiSession.LicenseSessionId = response.headers["x-tw-licsession-id"];
      apiSession.LicenseIssuedAt = new Date().valueOf();
      apiSession.LicenseExpiration = 0; //do not use
      await setApiSession(apiSession);
      return { validLogin: true, message: '' }
    } else {
      return { validLogin: false, message: response.data.errorMessage }
    }
  });

  return response;
};
