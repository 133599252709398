import { HttpMethods, sendRequest } from "../util/ApiHelper";

const CONTROLLER_NAME = "SecurityWindowAccess";
//note: add window names to the array as necessary
export const getWindowsAccessForPlant = async (
  userId: string,
  plant: string
) => {
  const data = {
    windowsList: [
      "mob_requester",
      "mob_technician",
      "w_wo_worker_time_eu",
      "mob_remarks",
      "mob_worker_time_reporting",
      "mob_eq_meter_readings",
      "mob_inspections",
      "mob_cca_codes",
      "mob_in_issues",
      "mob_in_return",
      "w_setup_user",
      "mob_stores",
      "mob_stores_issue",
      "mob_stores_return",
      "mob_stores_cycle_count",
      "mob_stores_bintobin",
      "mob_stores_itemadjust",
      "mob_wo_approvals",
    ],
    userid: userId,
    plant: plant,
  };
  return await sendRequest(
    HttpMethods.POST,
    `${CONTROLLER_NAME}/GetWindowsAccessForPlant`,
    data
  );
};
