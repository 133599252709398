import { completeInspectionResults } from "../../api/InspectionResults";
import { getWorkOrder } from "../../api/WorkOrders";
import { getCurrentPlant } from "../../util/ApiOptionsHelper";
import { getUserDisplayName, getUserId } from "../../util/UserHelper";
import { WorkOrder } from "../workorders/WorkOrder";

export const saveInspection = async (woNumber: string, inspectionId: string, pointValues: PointValue[], 
    inspectionDef: InspectionDef, failedFlag: boolean, comments?: string, testEquipment?: string) => {

    const data = await getInspectionResultsData(woNumber, inspectionId, pointValues, inspectionDef, failedFlag, comments, testEquipment);

    return await completeInspectionResults(data);
};

export const getInspectionResultsData = async (woNumber: string, inspectionId: string, pointValues: PointValue[], 
    inspectionDef: InspectionDef, failedFlag: boolean, comments?: string, testEquipment?: string) => {
    //load WO
    const respWo = await getWorkOrder({
        woNumber: woNumber,
        equipmentMaster: true,
        inspectionDefinition: true,
    });
    respWo.data.EquipmentMaster.Serial = null;
    const workOrder: WorkOrder = respWo.data;
    const empName = await getUserDisplayName();
    const userId = await getUserId();
    const plant = await getCurrentPlant();

    const data: InspectionResultsData = {
        Comments: comments!,
        Definition: inspectionDef,
        DocumentId: 0,
        EmployeeName: empName!,
        Equipment: workOrder.Equipment!,
        EquipmentLocation: workOrder.EquipmentMaster,
        FailedFlag: failedFlag,
        InspectedDatetime: (new Date().toISOString()),
        InspectionId: +inspectionId,
        Manufacturer: null,
        ManufacturerSerialId: null,
        Model: null,
        ObjectId: `${plant}|${workOrder.InspectionId}|0`,
        Plant: plant,
        Points: pointValues,
        ResultId: 0,
        RowVersion: null!,
        Serial: null,
        SerialId: "",
        SourceTableName: "ic_result",
        TestEquipment: !testEquipment ? [] : JSON.parse(testEquipment),
        UserId: userId!,
        WoBase: workOrder.WoBase!,
        WoSubtask: workOrder.WoSubtask!,
        WoTask: workOrder.WoTask!
    };

    return data;
};

export interface InspectionDef {
    InspectionId: number,
    Description: string,
    Pland: string,
    ObjectId: string,
    MaterialRequirements: string,
    Instructions: string,
    InspectionType: string,
    Points: PointDefType[],
    EquipmentInspections: any[],
    Elements: any[]
}

export interface PointDefType {
    PointId: number
    RowOrder: number,
    ObjectId: string,
    Description: string,
    RequiredFlag: boolean,
    ValueDataType: string,
    Label: string,
    Category: string,
    DefaultValue: string,
    Precision?: number,
    HighValue: number,
    LowValue: number,
    Limit: boolean,
    DesiredValue: number,
    Meta: [
        {
            MetaValue: number,
            MetaType: string,
            MetaSubtype: string
        }
    ]
    Columns: [
        {
            ImpliesFailureFlag: boolean,
            ColumnOrder: number,
            ColumnCode: string
            ObjectId: string
            Failed: boolean
        }
    ]
}

export interface PointValue {
    PointId: number;
    ObjectId: string;
    ColumnCode: string;
    Measure: string;
    FailedFlag: boolean;
}

export interface InspectionResultsData {
    Comments: string;
    Definition: any;
    DocumentId: number;
    EmployeeName: string;
    Equipment: string;
    EquipmentLocation?: any;
    FailedFlag: boolean;
    InspectedDatetime: string;
    InspectionId: number;
    Manufacturer: any;
    ManufacturerSerialId: any;
    Model: any;
    ObjectId: string;
    Plant: string;
    Points: PointValue[];
    ResultId: number;
    RowVersion: string;
    Serial: any;
    SerialId?: string;
    SourceTableName?: string;
    TestEquipment: [];
    UserId: string;
    WoBase: string;
    WoSubtask: string;
    WoTask: string;
}

export interface LocalInspectionsData {
    ActionResult: {
        MessageList: any[];
        Success: boolean;
    };
    Equipment: string;
    InspectionId: string;
    InspectionResultData: InspectionResultsData;
    Messages: any[];
    PrevStatus: string;
    Status: string;
    WorkOrder: string;
}