import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { ValidValue } from "../models/windows/GetColumnsWithGroup";
import { decodeParam } from "../util/ApiHelper";
import { useParams } from "react-router";
import { getCompletionDataFieldDef } from "../api/CompletionData";
import { WorkOrder, getFieldsByGroup } from "../models/workorders/WorkOrder";
import { getWorkOrder } from "../api/WorkOrders";
import { TranslationsContext } from "../util/Translations";
import {
  ProcessWorkOrderActionListData,
  changeStatus,
} from "../api/MobileApproval";

interface StatusModalProps {
  isOpen: boolean;
  cancel: () => void;
  confirm: () => void;
}

const Status: React.FC<StatusModalProps> = (props) => {
  const { isOpen, cancel, confirm } = props;
  const { translations } = useContext(TranslationsContext);
  const { workOrderId } = useParams<{ workOrderId: string }>();
  const displayWo = decodeParam(workOrderId);
  const [showLoading, setShowLoading] = useState(false);
  const [validStatusValues, setValidStatusValues] = useState<ValidValue[]>([]);
  const [status, setStatus] = useState<string>();
  const [comments, setComments] = useState<string>();
  const [presentAlert] = useIonAlert();

  const setup = async () => {
    setShowLoading(true);
    workOrderQuery();
    const metadata = await getCompletionDataFieldDef();
    const fields = getFieldsByGroup(metadata.data);
    const statusField = fields.find((field) => {
      return field.ColumnName === "status";
    });
    if (statusField?.ValidValues) {
      const values = statusField.ValidValues.filter((value) => {
        return +value.Code < 70 || +value.Code >= 80;
      });
      setValidStatusValues(values);
    }
    setShowLoading(false);
  };

  const workOrderQuery = async () => {
    if (displayWo) {
      getWorkOrder({
        woNumber: displayWo,
      }).then((response) => {
        setStatus(String(response.data.Status));
      });
    }
  };

  const updateStatus = async () => {
    if (!displayWo || !status) {
      return;
    }
    const data: ProcessWorkOrderActionListData = {
      RequestObject: {
        WorkOrderActionListData: [
          { WoBase: displayWo, Status: status, Comments: comments || "" },
        ],
      },
      Confirmation: {},
    };
    changeStatus(data).then((response) => {
      if (response.data.ResultMessage?.Success === true) {
        presentAlert({
          header: response.data?.ResultMessage?.MessageList[0]?.Text,
          backdropDismiss: false,
          buttons: [
            {
              text: translations["lbl_btn_ok"] || "OK",
              handler: () => {
                confirm();
                setComments("");
              },
            },
          ],
        });
      } else if (
        response.data?.ResultMessage?.MessageList[0].MessageType === 1
      ) {
        presentAlert({
          header: response.data?.ResultMessage?.MessageList[0]?.Text,
          buttons: [
            {
              text: response.data?.ResultMessage?.MessageList[0]
                ?.ValidResponses[0].DisplayText,
              role: "cancel",
            },
            {
              text: response.data?.ResultMessage?.MessageList[0]
                ?.ValidResponses[1].DisplayText,
              handler: () => {
                confirm();
                workOrderQuery();
                setComments("");
              },
            },
          ],
        });
      } else {
        presentAlert({
          header: response.data?.ResultMessage?.MessageList[0]?.Text,
          backdropDismiss: false,
          buttons: [
            {
              text: translations["lbl_btn_ok"] || "OK",
            },
          ],
        });
      }
    });
  };

  useEffect(() => {
    setup();
  }, []);

  return (
    <IonModal isOpen={isOpen}>
      <IonLoading isOpen={showLoading} />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                cancel();
                workOrderQuery();
              }}
            >
              {translations["lbl_btn_cancel"] || "Cancel"}
            </IonButton>
          </IonButtons>
          <IonTitle>
            {translations["lbl_mob_changestatus"] || "Change Status"}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => updateStatus()} disabled={!status}>
              {translations["lbl_btn_save"] || "Save"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonLabel position="stacked">
            {translations["lbl_woworkorder_status"] || "Status"}*
          </IonLabel>
          <IonSelect
            interface="popover"
            value={status}
            onIonChange={(ev) => {
              setStatus(ev.detail.value);
            }}
          >
            {validStatusValues.map((value, index) => {
              return (
                <IonSelectOption key={index} value={value.Code}>
                  {value.Code}
                  {!!value.Description && ` - ${value.Description}`}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            {translations["lbl_category_comments"] || "Comments"}
          </IonLabel>
          <IonInput
            placeholder={translations["lbl_enter comments"] || "Enter Comments"}
            onIonInput={(ev) => {
              setComments(String(ev.target.value));
            }}
          ></IonInput>
        </IonItem>
      </IonContent>
    </IonModal>
  );
};

export default Status;
