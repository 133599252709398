import { IonCard, IonLabel } from "@ionic/react";
import { useContext, useState, useEffect } from "react";
import { FilterParam } from "../hooks/useQueryExec";
import { ResultsParamBase } from "../models/common/ResultsParamBase";
import { TranslationMessagesContext } from "../util/Translations";
import { encodeParam } from "../util/ApiHelper";
import CardInfo from "./CardInfo";
import CardFilter from "./CardFilter";

interface QueryResultsParams extends ResultsParamBase {
  cardClick?: (arg0?: any) => void;
  cardLink?: string;
  openActionSheet?: (header: string) => void;
}

const QueryResults: React.FC<QueryResultsParams> = (props) => {
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const [searchExecuted, setSearchExecuted] = useState(false);
  const {
    searchYn,
    setSearchYn,
    queryName,
    queryParams,
    criteria,
    queryRows,
    setParameter,
    executeSearch,
    getNoOfFieldsToDisplay,
    errorState,
    totalCount,
    showLoading,
    setShowLoading,
    cardClick,
    cardLink,
    filterRows,
    filterMethod,
    filterString,
    openActionSheet,
  } = props;

  useEffect(() => {
    setShowLoading(true);
    Promise.all([
      setQueryParams(queryParams),
      getNoOfFieldsToDisplay("EqNoOfFieldsToDisplay"),
      beginSearch(),
    ]).finally(() => {
      setShowLoading(false);
    });
  }, [searchYn]);

  const setQueryParams = async (queryParams: FilterParam[] | undefined) => {
    if (setParameter && queryParams) {
      queryParams.forEach((p) => {
        setParameter(p.ColumnName, p.ParameterValue);
      });
    }
  };

  const beginSearch = async () => {
    if (!!searchYn) {
      await executeSearch(queryName, criteria);
      setSearchExecuted(true);
      setSearchYn(false);
    }
  };

  return (
    <>
      {errorState ? (
        <div hidden={errorState === ""} style={{ padding: 8 }}>
          <IonLabel color="danger">{errorState}</IonLabel>
        </div>
      ) : (
        <div
          hidden={
            !!showLoading ||
            (searchExecuted && totalCount > 0) ||
            !searchExecuted
          }
          style={{ padding: 8 }}
        >
          <IonLabel color={"warning"}>
            {translatedMessages["NoResultsFound"]?.MessageText ||
              "No results found."}
          </IonLabel>
        </div>
      )}
      {!!queryRows && queryRows.length > 0 && (
        <>
          <CardFilter
            queryRows={queryRows}
            resultsFilter={filterString}
            filterMethod={filterMethod}
          />
          {filterRows.map((row, index) => {
            return (
              <IonCard
                key={row.HeaderRow}
                onClick={() => {
                  //Send the value of the first column.
                  !!cardClick && cardClick(row.HeaderValue);
                  !!openActionSheet && openActionSheet(row.HeaderValue);
                }}
                routerLink={
                  !!cardLink && row.HeaderValue !== ""
                    ? `${
                        cardLink.includes("replaceWithValue")
                          ? cardLink.replace(
                              "replaceWithValue",
                              encodeParam(row.HeaderValue)
                            )
                          : `${cardLink}/${encodeParam(row.HeaderValue)}`
                      }`
                    : undefined
                }
              >
                <CardInfo header={row.HeaderValue} columns={row.Columns} />
              </IonCard>
            );
          })}
        </>
      )}
    </>
  );
};

export default QueryResults;
