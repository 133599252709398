//********************************************************************************
//* This file defines the Application Insights configuration for the application.
//* and global object to use when tracking events.
//********************************************************************************
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString:'InstrumentationKey=2ba401bd-c223-4fd7-8278-e41aca70500d;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/',
        instrumentationKey: '2ba401bd-c223-4fd7-8278-e41aca70500d',
        enableAutoRouteTracking: true, // adding track user navigation through the application
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
