import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import OnlineStatus from "./OnlineStatus";
import { useContext, useEffect, useState } from "react";
import { getListOfQueriesBasedOnModule } from "../api/QuerySetUp";
import {
  GetColumnsWithGroupField,
  GetColumnsWithGroupResponse,
  ValidValue,
} from "../models/windows/GetColumnsWithGroup";
import { ColumnsWithGroupData, getColumnsWithGroup } from "../api/Windows";
import { getListofFilters } from "../api/QueryDesigner";
import { executeQuery } from "../api/QueryExecution";
import { TranslationsContext } from "../util/Translations";
import { CycleCountResults } from "../models/materialissue/MaterialIssue";
import { getUserSetupValues } from "../api/UserSetups";
import { filterCircleOutline, filterCircleSharp } from "ionicons/icons";
import { useStoresManager } from "../hooks/useStoresManager";
import { encodeParam } from "../util/ApiHelper";

interface CycleQuery {
  QueryName: string;
}

const CycleCount: React.FC = () => {
  const {
    multiWarehouseCheck,
    storeLocation,
    setStoreLocation,
    checkMultiWarehouseOption,
    getDefaultStoreLocation,
    onStoreLocationSelect,
  } = useStoresManager();
  const { translations } = useContext(TranslationsContext);
  const [showLoading, setShowLoading] = useState(false);
  const [storeLocationField, setStoreLocationField] =
    useState<GetColumnsWithGroupField>();
  const [queryName, setQueryName] = useState<string>();
  const [dateField, setDateField] = useState<GetColumnsWithGroupField>();
  const [date, setDate] = useState<string>();
  const [statusField, setStatusField] = useState<GetColumnsWithGroupField>();
  const [status, setStatus] = useState<string>();
  const [cycleQuery, setCycleQuery] = useState<CycleQuery[]>([]);
  const [defaultCycleQuery, setDefaultCycleQuery] = useState<string>();
  const [cycleCountColumns, setCycleCountColumns] =
    useState<GetColumnsWithGroupResponse>();
  const [cycleCountStatusList, setCycleCountStatusList] = useState<
    ValidValue[]
  >([]);
  const [currentQuery, setCurrentQuery] = useState<string>("");
  const [queryResults, setQueryResults] = useState<CycleCountResults[]>([]);
  const [resultsFilter, setResultsFilter] = useState("");
  const [warningText, setWarningText] = useState<string>();
  const cycleCountName = "Cycle Count";
  const startup = "startup query";

  const getCycleCountColumns = async () => {
    const data: ColumnsWithGroupData = {
      WindowName: cycleCountName,
      IncludeValidValues: "true",
    };
    const response = await getColumnsWithGroup(data);
    return response.data as GetColumnsWithGroupResponse;
  };

  const getCycleQuery = async () => {
    setShowLoading(true);
    getListOfQueriesBasedOnModule({
      moduleName: cycleCountName,
      forQueryListing: true,
      forQueryExecution: false,
      includeQwColumns: true,
    }).then((response) => {
      setCycleQuery(response.data.QueryList);
      setDefaultCycleQuery(currentQuery);
      setShowLoading(false);
    });
  };

  const getDefaultCycleQuery = async () => {
    setShowLoading(true);
    const data = {
      Section: "QW--Cycle Count",
      ParameterList: [startup],
    };
    getUserSetupValues(data).then((response) => {
      if (response.data[startup].includes("--")) {
        let query = response.data[startup].split("--");
        setCurrentQuery(query[1]);
      } else setCurrentQuery(response.data[startup]);
      setShowLoading(false);
    });
  };

  const getCycleCountStatus = async () => {
    getListofFilters({
      includeValidValues: true,
      moduleName: cycleCountName,
      windowName: cycleCountName,
    }).then((response) => {
      setCycleCountStatusList(response.data[2]?.ValidValues);
    });
  };

  const executeCycleCountQuery = async () => {
    setQueryResults([]);
    let filterParams = [];
    const tableAlias = "in_cycle_count_batch";
    if (storeLocation) {
      filterParams.push({
        ColumnAlias: "stores_location",
        Operator: "like",
        ParameterValue: storeLocation,
        TableAlias: tableAlias,
      });
    }
    if (date) {
      filterParams.push({
        ColumnAlias: "created_datetime",
        Operator: "=",
        ParameterValue: new Date(date).toLocaleDateString(undefined, {
          timeZone: "UTC",
        }),
        TableAlias: tableAlias,
      });
    }
    if (status) {
      filterParams.push({
        ColumnAlias: "status",
        Operator: "like",
        ParameterValue: status,
        TableAlias: tableAlias,
      });
    }

    const data = {
      AskAtExecutionCriterias: [],
      EnableMultiPlantExecution: false,
      FilterParameters: filterParams,
      IsPublicQuery: true,
      ModuleName: cycleCountName,
      QueryName: queryName,
    };

    executeQuery(data).then((response) => {
      if (response.data.QueryResult.length === 0) {
        setWarningText(response.data.Messages[0].Text);
      } else {
        setWarningText("");
        setQueryResults(response.data.QueryResult);
      }
    });
  };

  const setup = async () => {
    setShowLoading(true);
    const cycleCountColumns = await getCycleCountColumns();
    setCycleCountColumns(cycleCountColumns);
    const defaultStore = await getDefaultStoreLocation();
    defaultStore && setStoreLocation(defaultStore);
    checkMultiWarehouseOption();
    setQueryName(defaultCycleQuery);
    setShowLoading(false);
  };

  const onQueryNameSelect = (value?: string) => {
    value && setQueryName(value);
  };

  const onDateSelect = (value?: string) => {
    setDate(value);
  };

  const onStatusSelect = (value?: string) => {
    value && setStatus(value);
  };

  const onFilterChange = async (event: any) => {
    setResultsFilter(event.target.value);
  };

  useIonViewWillEnter(() => {
    getDefaultCycleQuery();
  });

  useEffect(() => {
    getDefaultCycleQuery();
    setup();
  }, [defaultCycleQuery, currentQuery]);

  useEffect(() => {
    setShowLoading(true);
    getCycleCountStatus();
    if (cycleCountColumns) {
      getCycleQuery();
      const storeLocationField: GetColumnsWithGroupField =
        cycleCountColumns?.fields?.["in_cycle_count_batch.stores_location"];
      storeLocationField && setStoreLocationField(storeLocationField);
      // Default to only store location
      if (
        storeLocationField?.ValidValues &&
        storeLocationField.ValidValues.length === 1
      ) {
        const store = storeLocationField.ValidValues[0];
        setStoreLocation(store.Code);
      }
      const dateField =
        cycleCountColumns?.fields?.["in_cycle_count_batch.created_datetime"];
      dateField && setDateField(dateField);

      const statusField =
        cycleCountColumns?.fields?.["in_cycle_count_batch.status"];
      statusField && setStatusField(statusField);
      setShowLoading(false);
    }
  }, [cycleCountColumns]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>
            {translations["lbl_category_cyclecount"] || "Cycle Count"}
          </IonTitle>
        </IonToolbar>
        <OnlineStatus />
      </IonHeader>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={3000}
      />
      <IonContent>
        <IonItem>
          <IonLabel position="floating">
            {translations["lbl_grp_query"] || "Query"}
          </IonLabel>
          <IonSelect
            interface="popover"
            value={queryName}
            onIonChange={(ev) => {
              onQueryNameSelect(ev.detail.value);
            }}
          >
            {cycleQuery.map((query, index) => {
              return (
                <IonSelectOption
                  value={query.QueryName}
                  key={`${index}-${query}`}
                >
                  {query.QueryName}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </IonItem>
        {!!multiWarehouseCheck && multiWarehouseCheck === true && (
          <IonItem>
            <IonLabel position="floating">
              {storeLocationField?.TranslatedIdText}*
            </IonLabel>
            <IonSelect
              value={storeLocation}
              onIonChange={(ev) => {
                onStoreLocationSelect(ev.detail.value);
              }}
              interface="popover"
            >
              {storeLocationField?.ValidValues?.map((value) => {
                return (
                  <IonSelectOption
                    value={value.Code}
                    key={`store-location-${value.Code}-${value.Description}`}
                  >
                    {value.Code}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
        )}
        <IonItem>
          <IonLabel position="floating">{dateField?.TranslatedIdText}</IonLabel>
          <IonInput
            type="date"
            class="ion-text-end"
            onIonChange={(ev) => onDateSelect(String(ev.detail.value))}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">
            {statusField?.TranslatedIdText}
          </IonLabel>
          <IonSelect
            interface="popover"
            placeholder={translations["lbl_btn_select"] || "Select"}
            value={status}
            onIonChange={(ev) => onStatusSelect(ev.detail.value)}
          >
            {cycleCountStatusList?.map((status, index) => {
              return (
                <IonSelectOption value={status.Code} key={index}>
                  {status.Code}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </IonItem>
        <IonButton expand="full" onClick={() => executeCycleCountQuery()}>
          {translations["lbl_btn_search"] || "Search"}
        </IonButton>
        {warningText && <IonText color={"warning"}>{warningText}</IonText>}
        {!!queryResults && queryResults.length > 0 ? (
          <>
            <IonItem hidden={queryResults.length === 0}>
              <IonInput
                type="text"
                clearInput={true}
                debounce={500}
                class="ion-text-begin"
                placeholder={translations["lbl_filter"] || "Filter"}
                onIonChange={(ev) => onFilterChange(ev)}
                value={resultsFilter}
              ></IonInput>
              <IonIcon
                slot="end"
                ios={filterCircleOutline}
                md={filterCircleSharp}
              ></IonIcon>
            </IonItem>
            {queryResults.map((results, index) => {
              return (
                <IonCard
                  hidden={!results}
                  key={index}
                  routerLink={`/CycleCount/${encodeParam(
                    String(results?.batch_id_0)
                  )}`}
                >
                  <IonCardHeader>
                    <IonCardTitle>{results?.name_0}</IonCardTitle>
                    <IonCardSubtitle>{results?.status_0}</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonGrid>
                      <IonRow hidden={!results?.plant_0}>
                        <IonCol>
                          {translations["lbl_auauditchange_plant"] || "Plant"}
                        </IonCol>
                        <IonCol>{results?.plant_0}</IonCol>
                      </IonRow>
                      <IonRow hidden={!results?.batch_id_0}>
                        <IonCol>
                          {translations["lbl_incyclecountbatch_batchid"] ||
                            "Batch Id"}
                        </IonCol>
                        <IonCol>{results?.batch_id_0}</IonCol>
                      </IonRow>
                      <IonRow hidden={!results?.stores_location_0}>
                        <IonCol>
                          {translations[
                            "lbl_eqlocationsinquiryview_storeslocation"
                          ] || "Stores Location"}
                        </IonCol>
                        <IonCol>{results?.stores_location_0}</IonCol>
                      </IonRow>
                      <IonRow hidden={!results?.total_items_222}>
                        <IonCol>
                          {translations[
                            "lbl_invcyclecountcomputed_totalitems"
                          ] || "Total Items"}
                        </IonCol>
                        <IonCol>{results?.total_items_222}</IonCol>
                      </IonRow>
                      <IonRow hidden={!results?.uncounted_222}>
                        <IonCol>
                          {translations[
                            "lbl_invcyclecountcomputed_uncounted"
                          ] || "Uncounted"}
                        </IonCol>
                        <IonCol>{results?.uncounted_222}</IonCol>
                      </IonRow>
                      <IonRow hidden={!results?.completion_date_0}>
                        <IonCol>
                          {translations[
                            "lbl_incyclecountbatch_expectedcompletiondate"
                          ] || "Target Date to Complete"}
                        </IonCol>
                        <IonCol>{results?.completion_date_0}</IonCol>
                      </IonRow>
                      <IonRow hidden={!results?.completeness_222}>
                        <IonCol>
                          {translations[
                            "lbl_invcyclecountcomputed_completeness"
                          ] || "Completion"}
                        </IonCol>
                        <IonCol>{results?.completeness_222}</IonCol>
                      </IonRow>
                      <IonRow hidden={!results?.accuracy_222}>
                        <IonCol>
                          {translations["lbl_invcyclecountcomputed_accuracy"] ||
                            "Accuracy"}
                        </IonCol>
                        <IonCol>{results?.accuracy_222}</IonCol>
                      </IonRow>
                      <IonRow hidden={!results?.created_datetime_0}>
                        <IonCol>
                          {translations[
                            "lbl_incyclecountbatch_createddatetime"
                          ] || "Created Date"}
                        </IonCol>
                        <IonCol>
                          {new Date(
                            results?.created_datetime_0
                          ).toLocaleDateString()}
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </IonContent>
    </IonPage>
  );
};

export default CycleCount;
