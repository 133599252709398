import {
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import {
  bagHandleOutline,
  bagHandleSharp,
  buildOutline,
  buildSharp,
  homeOutline,
  homeSharp,
  cogOutline,
  cogSharp,
  createOutline,
  createSharp,
  hammerOutline,
  hammerSharp,
  construct,
  cart,
  settings,
  logOut,
  qrCodeOutline,
  qrCodeSharp,
  repeatOutline,
  repeatSharp,
  swapHorizontalOutline,
  swapHorizontalSharp,
  cubeOutline,
  cubeSharp,
  thumbsUp,
  readerOutline,
  readerSharp,
} from "ionicons/icons";
import "./Menu.css";

import { useContext, useEffect, useRef, useState } from "react";
import { TranslationsContext } from "../util/Translations";
import {
  getApprovalAccess,
  getBinAccess,
  getCycleCountAccess,
  getItemAdjustAccess,
  getStoresAccess,
  getUserRequesterAccess,
  getUserTechnicianAccess,
} from "../util/UserHelper";
import { AuthContext } from "../util/OAuth";
import { useLocation } from "react-router";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";

interface AppPage {
  url?: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  children?: AppPage[];
}

const Menu: React.FC = () => {
  const technician = "Technician";
  const stores = "Stores";
  const mobileApproval = "Mobile Approval";
  const settingsTab = "Settings";
  const signoff = "SignOff";
  const { translations } = useContext(TranslationsContext);
  const { loggedIn } = useContext(AuthContext);
  const location = useLocation();
  const [displayedAppPages, setDisplayedAppPages] = useState<AppPage[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [mainAccess, setMainAccess] = useState(false);
  const [storesAccess, setStoresAccess] = useState(false);
  const [approvalAccess, setApprovalAccess] = useState(false);
  const [tabLocation, setTabLocation] = useState<string>(technician);
  const router = useIonRouter();
  const menuRef = useRef<HTMLIonMenuElement>(null);

  const technicianAppPages: AppPage[] = [
    {
      title: translations["lbl_home"] || "Home",
      url: "/home",
      iosIcon: homeOutline,
      mdIcon: homeSharp,
    },
    {
      title: translations["lbl_WorkOrders"] || "Work Orders",
      url: "/WorkOrders",
      iosIcon: bagHandleOutline,
      mdIcon: bagHandleSharp,
    },
    {
      title: translations["lbl_category_inspection"] || "Inspection",
      url: "/MyInspections",
      iosIcon: hammerOutline,
      mdIcon: hammerSharp,
    },
    {
      title: translations["lbl_btn_equipment"] || "Equipment",
      url: "/Equipment",
      iosIcon: cogOutline,
      mdIcon: cogSharp,
    },
    {
      title: translations["lbl_btn_inventory"] || "Inventory",
      url: "/Inventory",
      iosIcon: cubeOutline,
      mdIcon: cubeSharp,
    },
    {
      title: translations["lbl_mob_scanqr"] || "Scan QR Code",
      iosIcon: qrCodeOutline,
      mdIcon: qrCodeSharp,
    },
  ];

  const requesterAppPages: AppPage[] = [
    {
      title: translations["lbl_home"] || "Home",
      url: "/home",
      iosIcon: homeOutline,
      mdIcon: homeSharp,
    },
    {
      title: translations["lbl_create_request"] || "Create Request",
      url: "/requester/create-request/equipment",
      iosIcon: createOutline,
      mdIcon: createSharp,
    },
    {
      title: translations["lbl_Work_Order_Search"] || "Work Order Search",
      url: "/requester/work-orders",
      iosIcon: bagHandleOutline,
      mdIcon: bagHandleSharp,
    },
    {
      title: translations["lbl_mob_scanqr"] || "Scan QR Code",
      iosIcon: qrCodeOutline,
      mdIcon: qrCodeSharp,
    },
  ];

  const storeAppPages: AppPage[] = [
    {
      title: translations["lbl_mob_issuereturn"] || "Issue/Returns",
      url: "/Stores",
      iosIcon: homeOutline,
      mdIcon: homeSharp,
    },
    {
      title: translations["lbl_btn_inventory"] || "Inventory",
      url: "/Inventory",
      iosIcon: cubeOutline,
      mdIcon: cubeSharp,
    },
  ];

  const binTransferTab = {
    title: translations["lbl_mob_bintransfer"] || "Bin Transfer",
    url: "/BinTransfer",
    iosIcon: swapHorizontalOutline,
    mdIcon: swapHorizontalSharp,
  };

  const cycleCountTab = {
    title: translations["lbl_category_cyclecount"] || "Cycle Count",
    url: "/CycleCount",
    iosIcon: repeatOutline,
    mdIcon: repeatSharp,
  };

  const itemAdjTab = {
    title: translations["lbl_mob_itemadjustment"] || "Item Adjustment",
    url: "/ItemAdjustment",
    iosIcon: buildOutline,
    mdIcon: buildSharp,
  };

  const mobileApprovalAppPages: AppPage[] = [
    {
      title: translations["lbl_WorkOrders"] || "Work Orders",
      url: "/WOApproval",
      iosIcon: bagHandleOutline,
      mdIcon: bagHandleSharp,
    },
    {
      title: translations["lbl_btn_requisition"] || "Requisition",
      url: "/RequisitionApproval",
      iosIcon: readerOutline,
      mdIcon: readerSharp,
    },
  ];

  const getAccessiblePages = async () => {
    if ((await getUserTechnicianAccess()) && tabLocation === technician) {
      setDisplayedAppPages([...technicianAppPages]);
    } else if ((await getUserRequesterAccess()) && tabLocation === technician) {
      setDisplayedAppPages([...requesterAppPages]);
    } else if ((await getStoresAccess()) && tabLocation === stores) {
      let storesAccessTab: AppPage[] = storeAppPages;
      if (await getBinAccess()) {
        storesAccessTab.push(binTransferTab);
      }
      if (await getCycleCountAccess()) {
        storesAccessTab.push(cycleCountTab);
      }
      if (await getItemAdjustAccess()) {
        storesAccessTab.push(itemAdjTab);
      }
      setDisplayedAppPages([...storesAccessTab]);
    } else if ((await getApprovalAccess()) && tabLocation === mobileApproval) {
      setDisplayedAppPages([...mobileApprovalAppPages]);
    }
  };

  const handleSegmentChange = async (userAccess: string) => {
    if (userAccess === technician) {
      setTabLocation(technician);
    }
    if (userAccess === stores) {
      setTabLocation(stores);
    }
    if (userAccess === mobileApproval) {
      setTabLocation(mobileApproval);
    }
    if (userAccess === settingsTab) {
      menuRef.current && menuRef.current.close();
      router.push("/Settings");
    }
    if (userAccess === signoff) {
      router.push("/logout");
    }
  };

  const setupAccessLevel = async () => {
    let techReq = false;
    let store = false;
    let approval = false;
    if ((await getUserTechnicianAccess()) || (await getUserRequesterAccess())) {
      techReq = true;
    }
    if (await getStoresAccess()) {
      store = true;
    }
    if (await getApprovalAccess()) {
      approval = true;
    }
    if (!techReq) {
      store && setTabLocation(stores);
      approval && setTabLocation(mobileApproval);
    }
    setMainAccess(techReq);
    setStoresAccess(store);
    setApprovalAccess(approval);
    getAccessiblePages();
  };

  const onBarcodeScan = (text?: string) => {
    text && setSearchValue(text);
    const urlParams = new URLSearchParams(text);
    const equipmentId = urlParams.get("Equipment");
    const redirectUrl = `/Equipment/${equipmentId}`;
    router.push(redirectUrl);
  };

  const startScan = async () => {
    await BarcodeScanner.checkPermission({ force: true });
    await BarcodeScanner.prepare();
    document.body.classList.add("qrscanner");
    const result = await BarcodeScanner.startScan();
    if (result.hasContent) {
      onBarcodeScan(result.content);
    }
    stopScan();
  };

  const stopScan = () => {
    BarcodeScanner.stopScan();
    document.body.classList.remove("qrscanner");
  };

  useEffect(() => {
    window.addEventListener("stop-barcode-scanning", () => {
      stopScan();
    });
  }, [searchValue]);

  useEffect(() => {
    setupAccessLevel();
  }, [location, translations, loggedIn, tabLocation]);

  return (
    <IonMenu contentId="main" type="overlay" ref={menuRef}>
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>EAM Mobile</IonListHeader>
          {displayedAppPages.map((appPage: AppPage, index) => {
            const menuItem = (item: AppPage) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={location.pathname === item.url ? "selected" : ""}
                    routerLink={item.url}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                    button
                    onClick={() => (!item.url ? startScan() : "")}
                  >
                    <IonIcon slot="start" ios={item.iosIcon} md={item.mdIcon} />
                    <IonLabel>{item.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            };
            return menuItem(appPage);
          })}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonSegment
            color="default"
            scrollable={true}
            value={tabLocation}
            onIonChange={(ev) => {
              handleSegmentChange(String(ev.detail.value));
            }}
          >
            {mainAccess && (
              <IonSegmentButton value={technician}>
                <IonIcon icon={construct} title="Technician"></IonIcon>
              </IonSegmentButton>
            )}
            {storesAccess && (
              <IonSegmentButton value={stores}>
                <IonIcon icon={cart} title="Stores"></IonIcon>
              </IonSegmentButton>
            )}
            {approvalAccess && (
              <IonSegmentButton value={mobileApproval}>
                <IonIcon icon={thumbsUp} title="Mobile Approval"></IonIcon>
              </IonSegmentButton>
            )}
            <IonSegmentButton value={settingsTab}>
              <IonIcon icon={settings} title="Settings"></IonIcon>
            </IonSegmentButton>
            <IonSegmentButton value={signoff}>
              <IonIcon icon={logOut} title="Sign Off"></IonIcon>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;
